import React from "react";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import _ from "lodash";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import AppCurrencyText from "../../../../../core/components/text/AppCurrencyText";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const OverallCosts = (props) => {

    const handleCarrierCostChange = async (e) => {
        const clone = _.cloneDeep(props.data);
        clone.invoice.carrierCost = e.target.value;
        clone.initialCarrierCost = e.target.value;
        props.setData(clone);
        props.setIsDirty(true);
    };

    const handleCustomerCostChange = async (e) => {
        const clone = _.cloneDeep(props.data);
        clone.invoice.customerCost = e.target.value;
        clone.initialCustomerCost = e.target.value;
        props.setData(clone);
        props.setIsDirty(true);
    };

    const adjustedCarrierCost = props.data.initialCarrierCost +
        _.sumBy(props.data.carrierIncreases, x => x.amount) +
        _.sumBy(props.data.carrierReductions, x => x.amount);

    const adjustedCustomerCost = props.data.initialCustomerCost +
        _.sumBy(props.data.customerIncreases, x => x.amount) +
        _.sumBy(props.data.customerReductions, x => x.amount);

    const totalCarrierCost = props.data.initialCarrierCost +
        _.sumBy(props.data.carrierIncreases, x => x.isIncludedInOverallCarrierCost ? x.amount : 0) +
        _.sumBy(props.data.carrierReductions, x => x.isIncludedInOverallCarrierCost ? x.amount : 0);

    const margin = adjustedCustomerCost - adjustedCarrierCost;

    let marginPercent = ((margin / adjustedCustomerCost) * 100)
    marginPercent = !isNaN(marginPercent) && isFinite(marginPercent) && margin > 0 ? `${marginPercent.toFixed(2)}%` : "N/A";

    const computeColor = (value) => {
        const parsedValue = parseInt(value);
        let color;

        if (parsedValue > 0) {
            color = "green";
        } else if (parsedValue === 0) {
            color = "gray";
        } else if (parsedValue < 0) {
            color = "red";
        } else {
            color = "gray";
        }
        return color;
    }


    return (
        <AppMuiCard title={"Overall Costs"}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs={6}>
                            <p>Orig. Carrier Cost</p>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"initialCarrierCost"}
                                name={"initialCarrierCost"}
                                onChange={handleCarrierCostChange}
                                value={props.data.initialCarrierCost}
                                spinners={false}
                                disabled={props.disableEdit}
                                format={"c2"}
                            />
                            <FieldError>{props.fieldErrors.initialCarrierCost}</FieldError>
                        </Grid>
                        <Grid item xs={6}>
                            <p>Orig. Customer Cost</p>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"initialCustomerCost"}
                                name={"initialCustomerCost"}
                                onChange={handleCustomerCostChange}
                                value={props.data.initialCustomerCost}
                                spinners={false}
                                disabled={props.disableEdit}
                                format={"c2"}
                            />
                            <FieldError>{props.fieldErrors.initialCustomerCost}</FieldError>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{color: "red"}}>Carrier Audit Cost <InfoIconTooltip title={
                                <>
                                    <p>The Carrier Audit Cost is specifically the amount we should owe the carrier.</p>
                                    <p>It is dependent upon the Orig. Carrier Cost and how (if any) Added/Deducted
                                        Carrier Costs are added.</p>
                                    <p>This will be the amount that is displayed on generated rate confirmations.</p>
                                </>
                            }
                            />
                            </p>

                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"totalCarrierCost"}
                                name={"totalCarrierCost"}
                                value={totalCarrierCost}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                                style={{outline: '1px solid red'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{color: "#000099"}}>Adj. Carrier Cost <InfoIconTooltip title={
                                <>
                                    <p>This is the total overall cost of the shipment which is used (with the Adj.
                                        Customer Cost) to determine the gross margin.</p>
                                    <p>It includes the Orig. Carrier Cost as well as any and all Added/Deducted Carrier
                                        Costs.</p>
                                </>
                            }
                            />
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"adjustedCarrierCost"}
                                name={"adjustedCarrierCost"}
                                value={adjustedCarrierCost}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                                style={{outline: '1px solid #000099'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{color: "#009900"}}>Adj. Customer Cost <InfoIconTooltip title={
                                <>
                                    <p>This is the total overall customer cost of the shipment which is used (with the
                                        Adj. Carrier Cost) to determine the gross margin.</p>
                                    <p>It includes the Orig. Customer Cost as well as any and all Added/Deducted
                                        Customer Costs.</p>
                                </>
                            }
                            />
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"adjustedCustomerCost"}
                                name={"adjustedCustomerCost"}
                                value={adjustedCustomerCost}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                                style={{outline: '1px solid #009900'}}
                            />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={4}>
                    <Grid container className={"margin-overview"} justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={12}>
                            <p>Margin <br/><AppCurrencyText style={{color: computeColor(margin)}} value={margin}/></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Margin % <br/><span style={{color: computeColor(marginPercent)}}>{marginPercent}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </AppMuiCard>
    )
}

export default OverallCosts;
