import {Tooltip} from "@material-ui/core";
import React from "react";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";
import "./TgfTooltip.css";

/**
 * A wrapper component around Material-UI's Tooltip that provides custom styling and width control
 * @component
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.tooltipContent - Content to display inside the tooltip
 * @param {number} [props.tooltipMaxWidth=300] - Maximum width of the tooltip in pixels
 * @param {React.ReactNode} props.children - The element(s) that will trigger the tooltip on hover
 * @example
 * // Basic usage
 * <TgfTooltip tooltipContent="This is a tooltip">
 *   <button>Hover me</button>
 * </TgfTooltip>
 *
 * // With custom max width
 * <TgfTooltip tooltipContent="Wide tooltip" tooltipMaxWidth={500}>
 *   <span>Hover for wide tooltip</span>
 * </TgfTooltip>
 * @returns {React.Component} A tooltip-wrapped component
 */
export const TgfTooltip = ({tooltipContent, tooltipMaxWidth = 300, children}) => {
    const classes = makeStyles((theme) => ({
        customWidth: {
            maxWidth: tooltipMaxWidth,
        },
    }))();

    return (
        <Tooltip
            classes={{tooltip: classes.customWidth}}
            title={<div className={"tgf-tooltip"}>{tooltipContent}</div>}
        >
            {children}
        </Tooltip>
    )
}

TgfTooltip.propTypes = {
    /** Content to show in the tooltip when hovering. Can be text or React elements */
    tooltipContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node
    ]).isRequired,

    /** Maximum width of the tooltip in pixels. Defaults to 300 */
    tooltipMaxWidth: PropTypes.number,

    /** The element(s) that will trigger the tooltip on hover */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.node
    ]).isRequired
};