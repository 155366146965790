// For dynamic label links
import React, {useState} from "react";
import {Box} from "@material-ui/core";
import {AddNewAddressModal} from "../../../core/components/AddNewAddressModal/AddNewAddressModal";

const AddNewAddressLabelLink = ({
        labelText,
        selectedCustomer,
        onSaveNewAddress,
        confirmNewAddress,
        styles = {color: 'black', fontWeight: 600, textDecoration: 'underline'}
    }) => {
    const labelStyle = {color: styles.color, textDecoration: styles.textDecoration, fontWeight: styles.fontWeight};
    const newAddressLabelStyle = {
        textDecoration: 'underline',
        color: "blue",
        background: "none", /* Remove button background */
        border: "none", /* Remove button border *//* Inherit color from parent (same as left text) */
        fontFamily: "inherit", /* Use the same font family as the left text */
        fontSize: "inherit", /* Use the same font size as the left text */
        cursor: "pointer", /* Change the cursor to pointer to indicate it's clickable */
        padding: 0, /* Remove any default padding */
        textAlign: "right"
    };

    const [showNewAddressModal, setShowNewAddressModal] = useState(false);

    const handleOpenModal = () => {
        setShowNewAddressModal(true);
    }

    const handleSaveNewAddress = (company) => {
        onSaveNewAddress(company);
        if (showNewAddressModal) {
            setShowNewAddressModal(false);
            confirmNewAddress(company);
        }
    }

    const closeNewAddressModal = () => {
        setShowNewAddressModal(false);
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%">
                <label style={labelStyle} >
                    {labelText}
                </label>
                <button
                    style={newAddressLabelStyle}
                    type='button'
                    onClick={handleOpenModal}
                >
                    New Address
                </button>
             </Box>
            {showNewAddressModal &&
                <AddNewAddressModal
                    selectedCustomer={selectedCustomer}
                    onSaveNewAddress={handleSaveNewAddress}
                    onNewAddressChange={confirmNewAddress}
                    onCloseNewAddress={closeNewAddressModal}
                />
            }
        </>
    );
};

export default AddNewAddressLabelLink;