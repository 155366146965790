import {useTgfRequest} from "../../hooks/useTgfRequest";
import TgfDialog from "../../kendo/dialog/TgfDialog";
import AppAlertStatic from "../AppAlertStatic";
import React from 'react';
import {TgfFormComponent} from "../../kendo/form/TgfFormComponent/components/TgfFormComponent/TgfFormComponent";
import * as yup from "yup";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Core from "@atomos/core";
import {TgfFormTextBoxInput} from "../../kendo/form/TgfFormComponent/components/inputs/TgfFormTextBoxInput";
import {TgfFormCustom} from "../../kendo/form/TgfFormComponent/components/custom/TgfFormCustom";
import {Typography} from "@material-ui/core";
import LabelOrLink from "../../../crm/components/cardHelperComponents/LabelOrLink";
import HotNote from "../../../areas/shipments/DetailsPage/includes/HotNote";
import {
    TgfFormStateProvinceComboBoxInput
} from "../../kendo/form/TgfFormComponent/components/inputs/TgfFormStateProvinceComboBoxInput";
import {TgfFormSubmitButton} from "../../kendo/form/TgfFormComponent/components/buttons/TgfFormSubmitButton";
import {TgfFormGenericButton} from "../../kendo/form/TgfFormComponent/components/buttons/TgfFormGenericButton";
import _ from "lodash";
import {TgfFormPhoneNumberInput} from "../../kendo/form/TgfFormComponent/components/inputs/TgfFormPhoneNumberInput";
import CrmTypedAddressBookListingComboBox from "../../../crm/components/CrmTypedAddressBookListingComboBox";
import Business from "@tgf-crm/business";

const GeneralRole = Business.Company.CompanyRoles.General;

const newAddressFormValidationSchema = yup.object().shape({
    entity: yup.object().shape({
        address1: yup.string().max(50).required('Address Line 1 is required.'),
        address2: yup.string().max(100).nullable(),
        businessPhone: Yup.string()
            .matches(Core.Text.PhoneRegExp, {message: 'Phone number is invalid.', excludeEmptyString: true})
            .required('Business Phone is required.'),
        city: yup.string().max(30).required('City is required.'),
        name: yup.string().max(50).required('Name is required.'),
        faxPhone: Yup.string()
            .matches(Core.Text.PhoneRegExp, {message: 'Fax number is invalid.', excludeEmptyString: true})
            .nullable(),
        firstName: yup.string().max(30).required('First name is required.'),
        lastName: yup.string().max(30).nullable(),
        stateProvince: yup.string().max(50).required("State/Province must be selected."),
        emailAddress: yup.string().max(100)
            .matches(/^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is invalid.')
            .nullable(),
        postalCode:
            Yup.string()
                .matches(/^\d{5,7}$/, 'Zip/Postal Code must be 5-7 digits.')
                .required('Zip/Postal Code is required'),
    })
});

export const AddNewAddressModal = (props) => {
    const {
        selectedCustomer,
        onSaveNewAddress,
        onNewAddressChange,
        onCloseNewAddress,

    } = props;

    const defaultCompany = {
        address1: null,
        address2: null,
        affiliateId: selectedCustomer?.companyId,
        businessPhone: null,
        categoryTypeId: 2,
        city: null,
        name: null,
        customerName: selectedCustomer?.companyName,
        faxPhone: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        stateProvince: null,
        postalCode: null,
        paymentTerms: 30
    };

    function alterCompany(addedCompany) {
        return {
            companyId: addedCompany?.id,
            companyName: addedCompany?.name,
            categoryTypeId: addedCompany?.categoryTypeId,
            categoryTypeName: "Shipper/Consignee",
            affiliateCompanyId: addedCompany?.affiliateId,
            affiliateCompanyName: selectedCustomer?.companyName,
            associateId: addedCompany?.associateId,
            associateFirstName: addedCompany?.firstName,
            associateLastName: addedCompany?.lastName,
            primaryContactId: null,
            primaryContactFirstName: null,
            primaryContactLastName: null,
            companyBusinessPhone: addedCompany?.businessPhone,
            companyFaxPhone: addedCompany?.faxPhone,
            companyEmailAddress: addedCompany?.emailAddress,
            companyAddress1: addedCompany?.address1,
            companyAddress2: addedCompany?.address2,
            companyCity: addedCompany?.city,
            companyStateProvince: addedCompany?.stateProvince,
            companyPostalCode: addedCompany?.postalCode,
            companyIsInactive: addedCompany?.isInactive ?? false,
            companyIsDisabled: addedCompany?.isDisabled ?? false,
            companyIsPodRequired: addedCompany?.isHardCopyPodRequired,
            hotNote: addedCompany?.hotNote,
            shippingHours: addedCompany?.shippingHours,
            receivingHours: addedCompany?.receivingHours,
        };
    }

    function createNewPrimaryContact(addedCompany) {
        let newContact;
        newContact = {
            companyId: addedCompany?.id,
            firstName: addedCompany?.firstName,
            lastName: addedCompany?.lastName,
            emailAddress: addedCompany?.emailAddress,
            phone: null,
            cellPhone: null,
            faxPhone: addedCompany?.faxPhone,
            isPrimary: true,
            companyContactRoles: [
                {companyId: addedCompany?.id, companyRoleId: GeneralRole.Id}
            ]
        };
        return newContact;
    }

    const [loadFormData] = useTgfRequest({
        onRequest: async () => {
            return {
                entity: {...defaultCompany}
            };
        },
        bubbleError: true
    });

    const [handleSubmit] = useTgfRequest({
        onRequest: async (values) => {
            if (values) {
                const newCompanyValues = _.cloneDeep(values?.entity);
                const newCompany = window.shell.gateway.coerceCompany(newCompanyValues);
                const addedCompany = await window.shell.gateway.saveCompany(newCompany);
                const newContact = createNewPrimaryContact(addedCompany);
                const addedNewContact = await window.shell.gateway.saveCompanyContact(newContact);
                const alteredAddedCompany = alterCompany(addedCompany);

                onSaveNewAddress(alteredAddedCompany);
                onNewAddressChange(alteredAddedCompany);
            }
        },
        successToast: "Company added. Please save changes.",
        errorToast: "Error. Please contact IT.",
        bubbleError: true
    });

    return (
        <TgfDialog
            title={`Add Shipper/Consignee`}
            onClose={onCloseNewAddress}
            width={1400}
        >
            <AppAlertStatic
                color={'warning'}
                title={
                    'This is a quick-addition experience so all normal options are not available. You can edit the address further as desired via the normal processes after it is saved.'
                }
                style={{marginBottom: "30px"}}
            />
            <TgfFormComponent
                loadFormData={loadFormData}
                validationSchema={newAddressFormValidationSchema}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} style={{paddingLeft:"1em"}}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TgfFormTextBoxInput
                                        label={"Name (50 max characters)"}
                                        name={"entity.name"}
                                        placeholder={"Enter company name..."}
                                        autoFocus
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} style={{marginTop: ".5em"}}>
                                <Grid item xs={12}>
                                    <TgfFormTextBoxInput
                                        label={"Address Line 1 (50 max characters)"}
                                        name={"entity.address1"}
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TgfFormTextBoxInput
                                        label={"Address Line 2 (100 max characters)"}
                                        name={"entity.address2"}
                                        maxLength={100}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TgfFormTextBoxInput
                                        label={"City (30 max characters)"}
                                        name={"entity.city"}
                                        maxLength={30}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <TgfFormStateProvinceComboBoxInput
                                        label={"State"}
                                        name={"entity.stateProvince"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TgfFormTextBoxInput
                                        label={"Zip/Postal Code"}
                                        name={"entity.postalCode"}
                                        maxLength={7}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} style={{paddingRight:"0px"}}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <LabelOrLink
                                        labelText={"Customer"}
                                        selected={selectedCustomer}
                                        styles={{color: 'black'}}
                                        forceLabel={true}
                                        tabSkip={'tabindex="-1"'}
                                    />
                                    {selectedCustomer?.hotNote &&
                                        <HotNote company={selectedCustomer} />
                                    }
                                    <CrmTypedAddressBookListingComboBox
                                        style={{width: '100%'}}
                                        types={[CrmTypedAddressBookListingComboBox.Customer]}
                                        value={selectedCustomer || null}
                                        popupIcon={' '}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TgfFormPhoneNumberInput
                                        label={"Business Phone"}
                                        name={"entity.businessPhone"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TgfFormPhoneNumberInput
                                        label={"Fax Phone"}
                                        name={"entity.faxPhone"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: "10px", marginBottom: "12.5px"}}>
                                <TgfFormCustom
                                    render={(tgfFormContext) => {
                                        return <Typography variant={"h6"} style={{color: "blue", width: "75%"}}>
                                            Contact entered below will be designated as the "Primary" contact
                                            automatically.
                                        </Typography>;
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TgfFormTextBoxInput
                                        label={"First Name (30 max characters)"}
                                        name={"entity.firstName"}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TgfFormTextBoxInput
                                        label={"Last Name (30 max characters)"}
                                        name={"entity.lastName"}
                                        maxLength={30}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TgfFormTextBoxInput
                                        label={"Email (100 max characters)"}
                                        name={"entity.emailAddress"}
                                        maxLength={100}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={5} spacing={1} style={{marginTop: "2em"}}>
                        <Grid item xs={2}>
                            <TgfFormSubmitButton
                                disabledFunction={(tgfFormContext) => {
                                    const disabled = (!tgfFormContext.canEdit || !tgfFormContext.isValid || !tgfFormContext.isDirty) ||
                                        (tgfFormContext.initialValues?.entity?.isHeadOfAgency === tgfFormContext.values?.entity?.isHeadOfAgency &&
                                            tgfFormContext.initialValues?.entity?.accessFilterId === tgfFormContext?.values?.entity?.accessFilterId);
                                    return disabled;
                                }}
                            >
                                SAVE
                            </TgfFormSubmitButton>
                        </Grid>
                        <Grid item xs={2}>
                            <TgfFormGenericButton
                                onClick={onCloseNewAddress}
                            >
                                CANCEL
                            </TgfFormGenericButton>
                        </Grid>
                    </Grid>
                </Grid>
            </TgfFormComponent>
        </TgfDialog>
    );
};
