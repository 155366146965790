import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {Box, Grid} from "@material-ui/core";
import LeftNav from "../LeftNav";
import {useDispatch, useSelector} from "react-redux";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AppMonthYear from "../../../core/components/inputs/AppDatePicker/AppMonthYear";
import BusinessDaysControl from "./includes/BusinessDaysControl";
import BusinessDaysButtonBar from "./includes/BusinessDaysButtonBar";

import './BusinessDaysPage.scss';

const BusinessDaysPage = (props) => {

    const personalDispatch = useDispatch();
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canEditBusinessDays = employeeContext.claims.canEditBusinessDays;

    usePageTitle("TGF: Business Days Control");

    const [isDirty, setIsDirty] = useState(false);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth() + 1);

    const [originalDates, setOriginalDates] = useState(null);
    const [updatedDates, setUpdatedDates] = useState();
    const [yearBusinessDays, setYearBusinessDays] = useState(0);
    const [monthBusinessDays, setMonthBusinessDays] = useState(0);

    useEffect(() => {
        if (selectedYear && selectedMonth) {
            loadOriginalDates();
            loadTotalBusinessDays();
        }
    }, [selectedYear, selectedMonth])

    const loadOriginalDates = async () => {
        const originalDates = await window.shell.gateway.getBusinessDays(selectedYear, selectedMonth);
        setOriginalDates([...originalDates]);
    };

    const loadTotalBusinessDays = async() => {
        const businessDays = await window.shell.gateway.getTotalBusinessDays(selectedYear, selectedMonth);
        setYearBusinessDays(businessDays.total.year);
        setMonthBusinessDays(businessDays.total.month);
    };

    const handleMonthYearChange = (monthYearValue) => {
        if (monthYearValue.isValid()) {
            const selectedDate = monthYearValue.toDate();
            setSelectedDate(selectedDate);
            setSelectedYear(selectedDate.getFullYear());
            setSelectedMonth(selectedDate.getMonth() + 1);
        }
    };

    const handleBusinessDayChange = (dates) => {
        setIsDirty(true)
        // Update dates based on values from business day control.
        setUpdatedDates([...dates.dates]);
    };

    const handleSubmit = async () => {
        await window.shell.gateway.updateBusinessDays(selectedYear, selectedMonth, updatedDates);
        await loadOriginalDates();
        await loadTotalBusinessDays();
        setIsDirty(false);

        personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
            content: 'Business Days Saved'}));
    };

    const handleReset = () => {
        setOriginalDates([...originalDates]);
        setUpdatedDates([...originalDates]);
        setIsDirty(false);
    };

    if (!canEditBusinessDays)
        return <AccessDeniedPage />;

    return (
        <FullWidthLayout SideNav={LeftNav} title={`Business Days Control`} className={"business-days-page"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BusinessDaysButtonBar isDirty={isDirty} handleSubmit={handleSubmit} handleReset={handleReset} />
                </Grid>
                <Grid item xs={4}>
                    Month/Year:
                    <AppMonthYear
                        openTo="year"
                        views={["year", "month"]}
                        inputVariant="outlined"
                        value={selectedDate}
                        onChange={handleMonthYearChange} />
                </Grid>
                <Grid item md={8} className={"total-business-days-container"}>
                    <Box>Viewed Year Total Business Days: {yearBusinessDays}</Box>
                    <Box>Viewed Month Total Business Days: {monthBusinessDays}</Box>
                </Grid>
                <Grid item xs={12}>
                    {selectedYear && selectedMonth && originalDates && <BusinessDaysControl
                        originalDates={originalDates}
                        givenYear={selectedYear}
                        givenMonth={selectedMonth - 1}
                        onChange={handleBusinessDayChange} />}
                </Grid>
            </Grid>
        </FullWidthLayout>);
};

export default BusinessDaysPage;
