// For dynamic label links
import {Link} from "react-router-dom";
import React from "react";

const LabelOrLink = ({labelText, selected, styles = {color: 'black'}, forceLabel = false}) => {
    const labelStyle = {textDecoration: 'underline', fontWeight: 600, ...styles}

    if (selected && !forceLabel && selected.mcNumber) {
        return (
            <label>
                <Link
                    style={labelStyle} to={`/carrier/${selected.mcNumber}/compliance`}
                    target={"_blank"}>
                    {labelText}
                </Link>
            </label>
        )
    } else if (selected && !forceLabel) {
        return (
            <label>
                <Link
                    style={labelStyle} to={`/address-book/${selected.companyId}/details`}
                    target={"_blank"}>
                    {labelText}
                </Link>
            </label>
        );
    }

    return <label style={{fontWeight: 400, color: styles.color}}>{labelText}</label>
}

export default LabelOrLink;
