import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const CostDescriptors = [
  { key: 'carrierCost', title: 'Carrier Cost' },
  { key: 'customerCost', title: 'Customer Cost' },
  { key: 'totalCarrierCost', title: 'Carrier Audit Cost' },
  { key: 'adjustedCarrierCost', title: 'Adj. Carrier Cost' },
  { key: 'adjustedCustomerCost', title: 'Adj. Customer Cost' }
];

const CostRow = ({ keyValue, title, value }) => (
  <React.Fragment>
    <Grid item xs={6} style={{ textAlign: 'right' }}>
        {keyValue === 'totalCarrierCost' && <InfoIconTooltip title={
            <>
                <p>The Carrier Audit Cost is specifically the amount we should owe the carrier.</p>
                <p>It is dependent upon the Orig. Carrier Cost and how (if any) Added/Deducted Carrier Costs are added.</p>
                <p>This will be the amount that is displayed on generated rate confirmations.</p>
            </>

        }/>}
      {title}
    </Grid>
    <Grid item xs={6}>
      <Typography
        variant={'h5'}>
        {value}
      </Typography>
    </Grid>
  </React.Fragment>
);

const CostSummary = (props) => {

  const processedCosts = CostDescriptors
    .map(({ key, title }) => ({ title, keyValue: key, value: (<AppCurrencyText value={props[key]} />) }));

  return (
    <AppMuiCard title="Cost Summary">
      <Grid container spacing={1}>
        {
          processedCosts.map((cost, i) =>
            <CostRow key={i} {...cost} />)
        }
      </Grid>
    </AppMuiCard >
  );
}

export default CostSummary;
