import {Button, Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ConfirmationModal from "../../../../../core/kendo/dialog/ConfimationModal";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfTextBox from "../../../../../core/kendo/form/inputs/TgfTextBox";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import useIsAdministrator from "../../../../../hubs/personaV2/selectors/useIsAdministrator";
import permissions from "../includes/permissions/financialPagePermissions";
import {ShipmentTransactionValidationSchema} from "./ShipmentTransactionValidationSchema";
import TgfToggle from "../../../../../core/kendo/form/inputs/TgfToggle";

const ShipmentTransactionModal = (props) => {
    const state = useSelector(state => state);
    const isAdmin = useIsAdministrator();
    const personalDispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(props?.data?.id);
    const [isValid, setIsValid] = useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        amount: props.data?.amount ? (Math.abs(props.data.amount)) : null,
        reason: props.data?.reason ? props.data.reason : "",
        isIncludedInOverallCarrierCost: props.data?.isIncludedInOverallCarrierCost ? props.data.isIncludedInOverallCarrierCost : true,
    });

    const [additionalFields, setAdditionalFields] = useState({
        miscCompany: (props.data?.payeeType === "Miscellaneous") ? props.data?.payeeName : null,
        shipperOrConsignee: (props.data?.payeeType === "Shipper/Consignee" || props.data?.payeeType === "Customer") ? props.data?.payeeName : null,
    });

    const fieldErrors = useFieldValidator(ShipmentTransactionValidationSchema, formData);

    useEffect(() => {
        ShipmentTransactionValidationSchema.validate(formData)
            .then(() => setIsValid(true))
            .catch((e) => setIsValid(false));
    }, [formData]);

    useEffect(() => {
        if (props.activeIndex !== null) {
            setFormData({
                amount: Math.abs(props.data[props.activeIndex].amount),
                reason: props.data[props.activeIndex].reason,
                isIncludedInOverallCarrierCost: props.data[props.activeIndex].isIncludedInOverallCarrierCost
            });
            setIsEditMode(true);
        }
    }, [props.activeIndex]);

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipmentAdjustment'));
            if (!formData.id) formData.created = new Date();

            if (props.activeIndex === null) {
                props.data.push({
                    ...formData,
                    amount: props.entryType.subType === "reduction" ? formData.amount * -1 : formData.amount,
                    modified: new Date(),
                });
            } else {
                props.data.splice(props.activeIndex, 1, {
                    ...formData,
                    amount: props.entryType.subType === "reduction" ? formData.amount * -1 : formData.amount,
                    modified: new Date(),
                });
            }

            setIsEditMode(true);
            props.setIsDirty(true);

            await props.onCloseDialog();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipmentAdjustment'));
        }
    };

    const handleDelete = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipmentAdjustment'));

            props.data.splice(props.activeIndex, 1);

            props.setIsDirty(true);

            await props.onCloseDialog();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipmentAdjustment'));
        }

    };

    const handleAmountChange = (e) => {
        setFormData({
            ...formData,
            amount: e.target.value
        });
        props.setIsDirty(true);
    };

    const handleReasonChange = (e) => {
        setFormData({
            ...formData,
            reason: e.target.value
        });
        props.setIsDirty(true);
    };

    const handleIsIncludedInOverallCarrierCostChange = (e) => {
        setFormData({
            ...formData,
            isIncludedInOverallCarrierCost: !e.target.value
        });
        props.setIsDirty(true);
    };


    const computeNote = () => {
        let note;
        if(!formData.isIncludedInOverallCarrierCost) {
            note = "NOTE – The entered cost will now NOT be included within the total carrier cost on generated rate confirmations. On the Financial Page, the entry will be displayed with a red asterisk as a visual cue for users.";
        }
        else if (props.entryType.type === "carrier" && props.entryType.subType === "increase") {
            note = "NOTE – The entered cost will be included within the total carrier cost on generated rate confirmations, but it will NOT be itemized out. Be sure to add appropriate Rate Con Notes on the shipment as needed to document the added cost.";
        } else if (props.entryType.type === "customer" && props.entryType.subType === "increase") {
            note = "NOTE – The entered Reason/Description and Cost WILL be included and itemized out on the involved invoice to the customer.";
        } else if (props.entryType.type === "carrier" && props.entryType.subType === "reduction") {
            note = "NOTE – The entered cost will be included within the total carrier cost on generated rate confirmations, but it will NOT be itemized out. Be sure to advise the agent to add appropriate Rate Con Notes on the shipment as needed.";
        } else if (props.entryType.type === "customer" && props.entryType.subType === "reduction") {
            note = "NOTE – The entered Reason/Description and Cost WILL be included and itemized out on the involved invoice to the customer";
        }
        return note;
    };

    const userCanEditDelete = !permissions.canEditDeleteShipmentAdj(isAdmin, props.shipment?.statusId, props.data?.payeeType, props.entryType.subType, props.disableEdit);

    return (
        <TgfDialog
            title={`${props?.data?.activeIndex !== null ? "Edit" : "Add"} ${props.entryType.type === "carrier" ? "Carrier" : "Customer"}
        ${props.entryType.subType === "increase" ? "Cost" : "Reduction"}`}
            onClose={props.onCloseDialog}
            width={600}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Label editorId={"reason"}>Reason / Description (30 max characters)</Label>
                    <TgfTextBox
                        id={"reason"}
                        name={"reason"}
                        value={formData.reason}
                        disabled={userCanEditDelete}
                        onChange={handleReasonChange}
                    />
                    <FieldError>{fieldErrors.reason}</FieldError>
                </Grid>
                <Grid item xs={4}>
                    <Label
                        editorId={"amount"}>{props.entryType.subType === "increase" ? "Cost" : "Reduction Amount"}</Label>
                    <NumericTextBox
                        id={"amount"}
                        name={"amount"}
                        onChange={handleAmountChange}
                        value={formData.amount}
                        disabled={userCanEditDelete}
                        spinners={false}
                        format={"c2"}
                    />
                    <FieldError>{fieldErrors.amount}</FieldError>
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                    {
                        props.entryType.type === "carrier" && <TgfToggle
                            label={<p style={{color: "blue"}}><strong>{`Click this toggle if this ${props.entryType.subType === "increase"  ? 'added' : 'reduction' } cost does not apply to the amount owed to the carrier.`}</strong></p>}
                            labelPosition={"right"}
                            id={"isIncludedInOverallCarrierCost"}
                            name={"isIncludedInOverallCarrierCost"}
                            checked={!formData.isIncludedInOverallCarrierCost}
                            onChange={handleIsIncludedInOverallCarrierCostChange}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <p>{computeNote()}</p>
                </Grid>
                {isEditMode ?
                    <>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                disabled={!isValid || userCanEditDelete}
                                onClick={handleSave}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={"delete-button"}
                                type={"button"}
                                onClick={() => setDeleteModalOpen(true)}
                                disabled={userCanEditDelete}
                            >
                                Delete
                            </Button>
                        </Grid>
                        {deleteModalOpen &&
                            <ConfirmationModal
                                title={`Confirm Deletion`}
                                description={"Are you sure you want to delete this entry?"}
                                action={handleDelete}
                                onClose={() => setDeleteModalOpen(false)}
                            />
                        }
                    </>
                    :
                    <>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                disabled={Boolean(!isValid)}
                                onClick={handleSave}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </TgfDialog>
    );
};

export default ShipmentTransactionModal;
