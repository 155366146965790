import React from "react";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid, Typography} from "@material-ui/core";
import TgfComboBox from "../../../../core/kendo/form/inputs/TgfComboBox";
import FieldError from "../../../../crm/components/inputs/FieldError";

const InvoiceConfigurationCard = (props) => {

    // const [disableIfBatchAll, setDisableIfBatchAll] = useState(false);
    //
    // const setDocumentFieldsToNo = async () => {
    //     const no = props.data.additionalData.includeOptions.find(option => option.id === "NO").id;
    //     props.setData({
    //         ...props.data,
    //         entity: {
    //             ...props.data.entity,
    //             includePod: no,
    //             includeShipmentAgreement: no,
    //             includeScaleTickets: no,
    //             includeLumperReceipt: no,
    //             includeTallySheet: no,
    //             includeInterchangeSlips: no
    //         }
    //     });
    // };

    const disableAutoInvoicingOptions = [
        {id: false, display: "Enabled"},
        {id: true, display: "Disabled" }
    ]

    // useEffect(() => {
    //     if(disableIfBatchAll) setDocumentFieldsToNo();
    // }, [disableIfBatchAll]);

    // useEffect(() => {
    //     if(props.data?.entity?.batchSize === 0) setDisableIfBatchAll(true);
    // }, [props.data])

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                [e.target.name]: e.target.value ? props.data.additionalData.includeOptions.find(option => option.id === e.target.value.id).id : null
            }
        });
        props.setIsDirty(true);
    };

    const handleBatchSizeChange = async (e) => {
        // if(e.target.value?.id === 0) {
        //     setDisableIfBatchAll(true)
        // } else {
        //     setDisableIfBatchAll(false);
        // }
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                batchSize: props.data.additionalData.emailBatchOptions.find(option => option.id === e.target.value?.id)?.id ?? null
            }
        });
        props.setIsDirty(true);
    };

    const handleDocumentGroupingChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                documentGrouping: e.target.value ? props.data.additionalData.docGroupings.find(option => option.id === e.target.value.id).id : null
            }
        });
        props.setIsDirty(true);
    };

    const handleDeliveryMethodChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                invoiceDeliveryMethod: e.target.value ? props.data.additionalData.deliveryMethods.find(option => option.id === e.target.value.id).id : null
            }
        });
        props.setIsDirty(true);
    };

    const handleDisableAutoInvoicingChange = async (e) => {
        props.setData({
            ...props.data,
            additionalData: {
                ...props.data.additionalData,
                automationDisabled: e.target.value ? disableAutoInvoicingOptions.find(option => option.id === e.target.value.id).id : null
            }
        });
        props.setIsDirty(true);
    }

    return (
        <AppMuiCard title={"Invoice Configuration Options"}>
            <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Auto Invoicing"}
                        id={"automationDisabled"}
                        name={"automationDisabled"}
                        data={disableAutoInvoicingOptions}
                        value={props.data?.additionalData?.automationDisabled}
                        onChange={handleDisableAutoInvoicingChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.automationDisabled}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Delivery Method"}
                        id={"invoiceDeliveryMethod"}
                        name={"invoiceDeliveryMethod"}
                        data={props.data?.additionalData?.deliveryMethods}
                        value={props.data?.entity?.invoiceDeliveryMethod}
                        onChange={handleDeliveryMethodChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Email Batch Size"}
                        id={"batchSize"}
                        name={"batchSize"}
                        data={props.data?.additionalData?.emailBatchOptions}
                        value={props.data?.entity?.batchSize}
                        onChange={handleBatchSizeChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.batchSize}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Document Attachment Method"}
                        id={"documentGrouping"}
                        name={"documentGrouping"}
                        data={props.data?.additionalData?.docGroupings}
                        value={props.data?.entity?.documentGrouping}
                        onChange={handleDocumentGroupingChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.documentGrouping}</FieldError>
                </Grid>
                { props.company?.isAutopay ?
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography variant="h3" style={{ color:'red'}} >* * * Autopay Customer * * *</Typography>
                  </Grid>
                  :
                  <Grid item xs={4} />
                }
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"POD"}
                        id={"includePod"}
                        name={"includePod"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includePod}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includePod}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Shipment Agreement"}
                        id={"includeShipmentAgreement"}
                        name={"includeShipmentAgreement"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includeShipmentAgreement}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includeShipmentAgreement}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Scale Ticket"}
                        id={"includeScaleTickets"}
                        name={"includeScaleTickets"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includeScaleTickets}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includeScaleTickets}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Lumper Receipt"}
                        id={"includeLumperReceipt"}
                        name={"includeLumperReceipt"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includeLumperReceipt}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includeLumperReceipt}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Tally Sheet"}
                        id={"includeTallySheet"}
                        name={"includeTallySheet"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includeTallySheet}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includeTallySheet}</FieldError>
                </Grid>
                <Grid item xs={2}>
                    <TgfComboBox
                        label={"Interchange Slip"}
                        id={"includeInterchangeSlips"}
                        name={"includeInterchangeSlips"}
                        data={props.data?.additionalData?.includeOptions}
                        value={props.data?.entity?.includeInterchangeSlips}
                        onChange={handleChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.includeInterchangeSlips}</FieldError>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default InvoiceConfigurationCard;
