import React from 'react';
import PropTypes from 'prop-types';
import "./TgfSimpleTable.scss";

/**
 * A reusable data table component that displays data in a tabular format
 * @param {Object} props - Component props
 * @param {Array} props.data - Array of objects containing the data to be displayed
 * @param {Array} props.columns - Array of column configuration objects
 * @returns {React.Component} A table component
 * @example
 * const data = [{ name: 'John', age: 30 }];
 * const columns = [
 *   { field: 'name', title: 'Name' },
 *   { field: 'age', title: 'Age' }
 * ];
 *
 * <TgfSimpleTable data={data} columns={columns} />
 */
export const TgfSimpleTable = ({ data = [], columns = [], fontColor}) => {

    if (!data || !Array.isArray(data)) {
        console.error("Invalid data provided to TgfSimpleTable...")
    }

    return (
        <div>
            <table className={"tgf-simple-table"} style={{color: fontColor ? fontColor : "inherit"}}>
                <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={column.field || index}>
                            {column.title}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                            <td key={`${rowIndex}-${colIndex}`}>
                                {row[column.field]}
                            </td>
                        ))}
                    </tr>
                ))}
                {data.length === 0 && (
                    <tr>
                        <td colSpan={columns.length}>
                            No data available
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

// PropType definitions for the data array items
const DataItemShape = PropTypes.objectOf(
    PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ])
);

// PropType definitions for the column configuration objects
const ColumnShape = PropTypes.shape({
    /** The field name in the data object that this column corresponds to */
    field: PropTypes.string.isRequired,
    /** The display title for the column header */
    title: PropTypes.string.isRequired
});

TgfSimpleTable.propTypes = {
    /** Array of data objects to be displayed in the table */
    data: PropTypes.arrayOf(DataItemShape).isRequired,

    /** Array of column configuration objects that define the table structure */
    columns: PropTypes.arrayOf(ColumnShape).isRequired
};

