import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import "./WarningText.css";

/**
 * WarningText component is used to display warning messages in a styled span element.
 *
 * @component
 * @example
 * // Usage:
 * <WarningText>Warning: This action cannot be undone!</WarningText>
 *
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - The content to display inside the warning span.
 * @param {React.Ref} ref - The ref to forward to the underlying span element.
 *
 * @returns {JSX.Element} The warning message styled in a span element.
 */
export const WarningText = forwardRef((props, ref) => {

    // note. We must currently forward the props to our span in order for a mui tooltip to do its job. (react 18 / mui v4)
    return (
        <span {...props} className="warning-text" ref={ref}>
            {props.children}
        </span>
    );
});

// PropTypes validation
WarningText.propTypes = {
    children: PropTypes.node.isRequired,
};
