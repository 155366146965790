import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import AllTimeHighsListing from "./AllTimeHighsListing";
import AppMuiCheckbox from "../../../../core/components/inputs/AppCheckbox";

const AdminAllTimeHighsListing = (props, ref) => {

    const gridRef = useRef();
    const associateGridRef = useRef();

    const [relatedData, setRelatedData] = useState([]);
    const [associateRelatedData, setAssociateRelatedData] = useState([]);

    const [orderBy, setOrderBy] = useState(['fullName asc']);
    const [gridReady, setGridReady] = useState(false);
    const [associateGridReady, setAssociateGridReady] = useState(false);

    const [viewAllAssociates, setViewAllAssociates] = useState(false);
    const handleViewAllAssociatesChange = (e, checked) => {
        setViewAllAssociates(checked);
    };

    const loadGridData = async (odata) => {

        odata.viewAllAssociates = viewAllAssociates ? 1 : 0;

        const selectedResultType = props.resultType.value.toLowerCase();
        odata[selectedResultType] = 1;

        const data = await window.shell.gateway.calculateAllTimeHighs(odata);
        setRelatedData(data.data);
        setGridReady(true);

        return data;
    };

    const loadAssociateGridData = async (odata) => {

        odata.viewAllAssociates = viewAllAssociates ? 1 : 0;

        const selectedResultType = props.resultType.value.toLowerCase();
        odata[selectedResultType] = 1;

        const data = await window.shell.gateway.calculateAssociateAllTimeHighs(odata);
        setAssociateRelatedData(data.data);
        setAssociateGridReady(true);

        return data;
    };

    useEffect(() => {
        if (gridReady)
            gridRef?.current?.refresh();
        if (associateGridReady)
            associateGridRef?.current?.refresh();
    }, [viewAllAssociates, props.resultType]);

    return (<Box className={'all-time-highs-admin-listing'}>
        <Box>
            <Typography variant={"h5"}>Company</Typography>
            <AllTimeHighsListing
                ref={gridRef}
                pageable={{
                    pageSize: 1,
                    buttonCount: 10,
                    pageSizes: [1],
                }}
                onLoadDataPage={loadGridData}
                orderBy={orderBy}
                sort={[{field: "fullName", dir: "asc"}]}
                relatedData={relatedData}  />
        </Box>
        <Grid container>
            <Grid item xs={1} className={'agents-header'}>
                <Typography variant={"h5"}>Agents</Typography>
            </Grid>
            <Grid item xs={11}>
                <AppMuiCheckbox
                    label={"View all/past agents"}
                    checked={viewAllAssociates}
                    onChange={handleViewAllAssociatesChange} />
            </Grid>
            <Grid item xs={12}>
                <AllTimeHighsListing
                    ref={associateGridRef}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500],
                    }}
                    onLoadDataPage={loadAssociateGridData}
                    orderBy={orderBy}
                    sort={[{field: "fullName", dir: "asc"}]}
                    relatedData={associateRelatedData}
                />
            </Grid>
        </Grid>
    </Box>)
};

export default AdminAllTimeHighsListing;