import React, {useRef, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import LeftNav from "../LeftNav";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import TgfDataGrid from "../../../core/kendo/dataGrid/TgfDataGrid";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import ConfirmationModal from "../../../core/kendo/dialog/ConfimationModal";
import {Box, Grid} from "@material-ui/core";

import './TwoYearDisablePage.scss';
import TgfDialog from "../../../core/kendo/dialog/TgfDialog";
import AppSpinner from "../../../core/components/AppSpinner";
import {Link} from "react-router-dom";

const TwoYearDisablePage = (props) => {

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canTwoYearDisable = employeeContext.claims.canTwoYearDisable;

    usePageTitle("TGF: Two Year Disable");

    const gridRef = useRef();
    const [orderBy, setOrderBy] = useState(['company asc']);
    const [count, setCount] = useState();
    const [confirmDisableModalOpen, setConfirmDisableModalOpen] = useState(false);
    const [disableCompleteModalOpen, setDisableCompleteModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const  loadData = async (odata) => {
        const data = await window.shell.gateway.getStaleCandidates(odata);
        setCount(data.totalItemCount);
        return data;
    };

    const disableStaleCandidates = async () => {
        setConfirmDisableModalOpen(false);
        setLoading(true);
        await window.shell.gateway.disableStaleCandidates();
        setLoading(false);
        setDisableCompleteModalOpen(true);
    }

    const handleDisableCompleteModalClose = () => {
        setDisableCompleteModalOpen(false)
        gridRef?.current.refresh();
    }

    const GridColumns = [
        {
            field: 'agent',
            title: 'Agent',
            type: 'string',
            width: 150
        },
        {
            field: 'company',
            title: 'Customer',
            type: 'custom',
            cell: (props) => {
                return <td><Link className={"tgf-grid-link-cell"} to={`/address-book/${props.dataItem.customerId}/details`} target={"_blank"}>{props.dataItem.company}</Link></td>;
            },
            width: 300
        },
        {
            field: 'lastShipmentDate',
            title: 'Last Shipment Date',
            type: 'date',
            width: 150
        },
        {
            field: 'ysnCustomerDisabled',
            title: 'Credit Disabled',
            type: 'custom',
            cell: (props) => {
                return <td>{props.dataItem.ysnCustomerDisabled ? 'Yes' : 'No'}</td>;
            },
            width: 100
        },
        {
            field: 'createDate',
            title: 'Latest Reason Date',
            type: 'date',
            width: 150
        },
        {
            field: 'associate',
            title: 'Associate',
            type: 'string',
            width: 150
        },
        {
            field: 'reasonText',
            title: 'Latest Reason',
            type: 'string',
            width: 300
        },
        {
            field: 'classificationCode',
            title: 'Latest Classification',
            type: 'string',
            width: 150
        }
    ];

    if (!canTwoYearDisable)
        return <AccessDeniedPage />;

    return (
    <FullWidthLayout SideNav={LeftNav} title={`Two Year Disable`} className={"two-year-disable-page"}>
        <AppSpinner isLoading={isLoading} />
        <Grid container spacing={2}>
            <Grid item>
                <p>Clicking the below button will automatically disable any customer who has not shipped in the last two years that is not currently manually disabled.</p>
                <p>This is determined by the "Last Pickup Date" within the Financial Summary card on the customer profile's Financial Health page.</p>
                <p>Once the customer is disabled from the action below, the involved agent will receive the standard "Customer Manually Disabled By Back Office Personnel" automated email.</p>
                <AppButton
                    disabled={count == 0}
                    onClick={() => setConfirmDisableModalOpen(true)}>
                    Disable Involved Customers
                </AppButton>
            </Grid>
            <Grid item>
                <TgfDataGrid
                    ref={gridRef}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500],
                    }}
                    onLoadDataPage={loadData}
                    orderBy={orderBy}
                    sort={[{field: "company", dir: "asc"}]}
                    gridColumns={GridColumns} />
            </Grid>
            {confirmDisableModalOpen && count &&
                <ConfirmationModal
                    title={'Confirm Disable Customers'}
                    description={`Please confirm you want to run the Two Year Disable process for ${count ?? 0} customers?`}
                    action={disableStaleCandidates}
                    onClose={() => setConfirmDisableModalOpen(false)}
                />}
            {disableCompleteModalOpen &&
                <TgfDialog
                    title={'Two Year Disable'}
                    onClose={handleDisableCompleteModalClose}>
                    The process has completed.
                    <Box textAlign={'center'} paddingTop={'10px'}>
                        <AppButton onClick={handleDisableCompleteModalClose}>Close</AppButton>
                    </Box>
                </TgfDialog>}
        </Grid>
    </FullWidthLayout>);
};

export default TwoYearDisablePage;
