import React from 'react';
import AppNumberText from "../../../../core/components/text/AppNumberText";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const AgingShipmentTotalAmountDue = ({totalAmountDue}) => {
    return (
        <div className={'customer-total-amount-due-div'}>
            <InfoIconTooltip
                title={"Shipments below in Dispute or Claim status that have already been paid are not included in this total."}
                style={{ whiteSpace: 'pre-line', display: "flex", alignItems: "center" }}
            />
            <span>Current Total Amount Owed: $</span>
            <AppNumberText style={{color:'red'}} value={totalAmountDue}/>
        </div>
    )
}

export default AgingShipmentTotalAmountDue;