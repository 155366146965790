import React from 'react';

import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import {Box, Tooltip} from "@material-ui/core";
import AppPercentText from "../../../../core/components/text/AppPercentText";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";

const AllTimeHighsListing = React.forwardRef((props, ref) => {

    const getCellData = (data, field, relatedField) => {
        const value = data[field];
        const relatedData = props.relatedData.filter(d => (d.associateId === data.associateId || !data.associateId) && d[relatedField]?.toFixed(2) === value?.toFixed(2))[0];
        const tooltip = relatedData ? formatTooltip(relatedData) : '';
        return { value, tooltip };
    };

    const AllTimeHighCurrencyCell = (data, field, relatedField) => {
        const { value, tooltip } = getCellData(data, field, relatedField);
        return <td>
            <Tooltip title={tooltip}>
              <Box><AppCurrencyText value={value ?? 0} /></Box>
            </Tooltip>
        </td>;
    };

    const AllTimeHighPercentCell = (data, field, relatedField) => {
        const { value, tooltip } = getCellData(data, field, relatedField);
        return <td>
            <Tooltip title={tooltip}>
                <Box><AppPercentText value={value ?? 0} /></Box>
            </Tooltip>
        </td>;
    };

    const AllTimeHighNumberCell = (data, field, relatedField) => {
        const { value, tooltip } = getCellData(data, field, relatedField);
        return <td>
            <Tooltip title={tooltip}>
                <Box>{value}</Box>
            </Tooltip>
        </td>;
    };

    const formatTooltip = (data) => {

        let tooltip = data.shipmentYear;

        if (data.shipmentMonth) {
            const date = new Date();
            date.setMonth(data.shipmentMonth - 1);
            const month = date.toLocaleString('default', { month: 'long' });
            tooltip = month + ' / ' + tooltip;
        }

        return tooltip;
    }
    const GridColumns = [
        {
            field: 'fullName',
            title: 'Agent Name',
            dataType: 'string',
            width: 150,
        },
        {
            field: 'totalShipmentsHigh',
            title: 'Total Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighNumberCell(props.dataItem, 'totalShipmentsHigh', 'totalShipments'),
            width: 90,
        },
        {
            field: 'tlTotalShipmentsHigh',
            title: 'TL Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighNumberCell(props.dataItem, 'tlTotalShipmentsHigh', 'tlTotalShipments'),
            width: 90,
        },
        {
            field: 'ltlTotalShipmentsHigh',
            title: 'LTL Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighNumberCell(props.dataItem, 'ltlTotalShipmentsHigh', 'ltlTotalShipments'),
            width: 90,
        },
        {
            field: 'totalMarginHigh',
            title: 'Total GM All Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'totalMarginHigh', 'totalMargin'),
            width: 90,
        },
        {
            field: 'tlTotalMarginHigh',
            title: 'Total TL GM',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'tlTotalMarginHigh', 'tlTotalMargin'),
            width: 90,
        },
        {
            field: 'ltlTotalMarginHigh',
            title: 'Total LTL GM',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'ltlTotalMarginHigh', 'ltlTotalMargin'),
            width: 90,
        },
        {
            field: 'avgMarginHigh',
            title: 'Avg GM All Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'avgMarginHigh', 'avgMargin'),
            width: 90,
        },
        {
            field: 'tlAvgMarginHigh',
            title: 'Avg TL GM',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'tlAvgMarginHigh', 'tlAvgMargin'),
            width: 90,
        },
        {
            field: 'ltlAvgMarginHigh',
            title: 'Avg LTL GM',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'ltlAvgMarginHigh', 'ltlAvgMargin'),
            width: 90,
        },
        {
            field: 'avgMarginPercentHigh',
            title: 'Avg GM % All Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighPercentCell(props.dataItem, 'avgMarginPercentHigh', 'avgMarginPercent'),
            width: 90,
        },
        {
            field: 'tlAvgMarginPercentHigh',
            title: 'Avg TL GM %',
            type: 'custom',
            cell: (props) => AllTimeHighPercentCell(props.dataItem, 'tlAvgMarginPercentHigh', 'tlAvgMarginPercent'),
            width: 90,
        },
        {
            field: 'ltlAvgMarginPercentHigh',
            title: 'Avg LTL GM %',
            type: 'custom',
            cell: (props) => AllTimeHighPercentCell(props.dataItem, 'ltlAvgMarginPercentHigh', 'ltlAvgMarginPercent'),
            width: 90,
        },
        {
            field: 'avgRevenueHigh',
            title: 'Avg Revenue All Shipments',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'avgRevenueHigh', 'avgRevenue'),
            width: 90,
        },
        {
            field: 'tlAvgRevenueHigh',
            title: 'Avg TL Revenue',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'tlAvgRevenueHigh', 'tlAvgRevenue'),
            width: 90,
        },
        {
            field: 'ltlAvgRevenueHigh',
            title: 'Avg LTL Revenue',
            type: 'custom',
            cell: (props) => AllTimeHighCurrencyCell(props.dataItem, 'ltlAvgRevenueHigh', 'ltlAvgRevenue'),
            width: 90,
        },
    ];

    return (
        <TgfDataGrid
            {...props}
            ref={ref}
            gridColumns={GridColumns} />
    )
});

export default AllTimeHighsListing;