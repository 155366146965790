import React, {useEffect, useState} from "react";
import AppCurrencyText from "../../../core/components/text/AppCurrencyText";

const TotalRevenuePace = (props) => {

    const [monthYearValid, setMonthYearValid] = useState(false);
    const [monthBusinessDaysTotal, setMonthBusinessDaysTotal] = useState(0);
    const [monthBusinessDaysToDate, setMonthBusinessDaysToDate] = useState(0);
    const { date, grossMarginData } = props;

    // If selection is not equal to current month, then we return n / a
    useEffect( () => {

        const year = date.getFullYear();
        const month = date.getMonth();
        const isMonthYearValid = date && `${year}${month}` === `${new Date().getFullYear()}${new Date().getMonth()}`;
        setMonthYearValid(isMonthYearValid);

        window.shell.gateway.getTotalBusinessDays(year, month + 1).then((businessDays) => {
            setMonthBusinessDaysTotal(businessDays?.total?.month ?? 1)
            setMonthBusinessDaysToDate(businessDays?.toDate?.month ?? 1)
        });

    }, [date]);

    const getTotalRevenuePace = (marginTotal) => {
        return (marginTotal / monthBusinessDaysToDate) * monthBusinessDaysTotal;
    }

    let totalRevenuePace;
    if(grossMarginData.total)
        totalRevenuePace = getTotalRevenuePace(grossMarginData.total.customerCost);

  return (monthYearValid
      ? <AppCurrencyText value={totalRevenuePace}></AppCurrencyText>
      : <AppCurrencyText value={grossMarginData.total.customerCost}></AppCurrencyText>);
};

export default TotalRevenuePace;
