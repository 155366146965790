import React, {useRef} from "react";
import {Tooltip} from "@material-ui/core";
import AppBackToTopButton from "../../AppButton/AppBackToTopButton";

const ScrollableTooltip = (props) => {
    const {
        titleContent,
        container,
        setIsHovered,
        maxHeight = 100,
    } = props;

    const scrollref = useRef(null);

    const tooltipTitle =
        <div
            ref={scrollref}
            style={{
                fontSize: ".9rem",
                padding: "0px",
                overflowY: "scroll",
                zIndex: 1,
                maxHeight: maxHeight,
            }}
        >
            <div
                style={{padding: 1}}
            >
                {titleContent}
            </div>
            <AppBackToTopButton
                tooltip={'Back to top'}
                scrollref={scrollref}
                onDarkBackground={true}
                buttonBottomPosition={22}
                buttonRightPosition={10}
            />
        </div>;

    const tooltipProps = {
        container: props.container,
        TransitionComponent: props.TransitionComponent,
        TransitionProps: props.TransitionProps,
        enterDelay: props.enterDelay,
        enterNextDelay: props.enterNextDelay,
        leaveDelay: props.leaveDelay
    };


    return (
        <Tooltip
            interactive
            title={tooltipTitle}
            {...tooltipProps}
        >
            <span onMouseEnter={() => setIsHovered(true)}>
                {container}
            </span>
        </Tooltip>

    );
};

export default ScrollableTooltip;