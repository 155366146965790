import React, {useEffect, useState} from "react";
import AppMuiCard from "../../../core/components/cards/AppCard";
import AppText from "../../../core/components/text/AppText";
import TotalRevenuePace from "./TotalRevenuePace";

const TotalRevenuePaceTable = (props) => {

  const {date, grossMarginData} = props;

  return grossMarginData?.total ? (
    <AppMuiCard>
      <h1>
        <AppText
          value={" Total Revenue Pace: "}
          style={
            grossMarginData.total.margin < 0
              ? { color: "red" }
              : { color: "blue" }
          }
        ></AppText>
        <TotalRevenuePace date={date} grossMarginData={grossMarginData} />
      </h1>
    </AppMuiCard>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default TotalRevenuePaceTable;
