import React from "react";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import AppCurrencyText from "../../../../../core/components/text/AppCurrencyText";
import "./css/FinancialAdjustmentCard.scss";
import {useSelector} from "react-redux";
import useIsAdministrator from "../../../../../hubs/personaV2/selectors/useIsAdministrator";
import permissions from "./permissions/financialPagePermissions";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import {Error, FindInPage as FindInPageIcon, PriorityHigh} from "@material-ui/icons";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const FinancialAdjustmentCell = ({data, onAddEditEntryClick, entryType, activeIndex}) => {
    return (
        <Grid container alignItems={"center"} className={"financial-adjustment_row"}>
            <Grid item xs={6}>
                <span style={{
                    color: "red",
                    visibility: !data[activeIndex].isIncludedInOverallCarrierCost ? "" : "hidden"
                }}>* </span>
                {data[activeIndex].reason}
            </Grid>
            <Grid item xs={3} style={{color: entryType.subType === "reduction" ? "red" : "inherit"}}>
                <AppCurrencyText value={data[activeIndex].amount}/>
            </Grid>
            <Grid item xs={3}>
                <a
                    style={{color: "blue"}}
                    onClick={() => onAddEditEntryClick(data, entryType, activeIndex)}
                >View Entry</a>
            </Grid>
        </Grid>
    )
}

const FinancialTransactionCard = (props) => {
    const state = useSelector(state => state)
    const isAdmin = useIsAdministrator();

    const computeToolTip = () => {
        if(props.entryType.subType === "increase"){
            return <>
                <p>
                    When adding carrier costs, there is an option to not apply the involved cost to the amount owed to the carrier.
                </p>
                <p>
                    Added costs not applied to the amount owed to the carrier will be displayed below with a red asterisk and will not be included within the Carrier Audit Cost amount.
                </p>
            </>
        }
        else {
            return <>
                <p>
                    When deducting carrier costs, there is an option to not apply the involved cost to the amount owed to the carrier.
                </p>
                <p>
                    Deducted costs not applied to the amount owed to the carrier will be displayed below with a red asterisk and will not be included within the Carrier Audit Cost amount.
                </p>
            </>
        }
    }

    return (
        <AppMuiCard
            title={<div
                style={{display: 'flex'}}>{props.entryType.subType === "increase" ? "Added " : "Deducted "}
                {props.entryType.type === "carrier" ? "Carrier" : "Customer"} Costs
                {props.entryType.type === "carrier" ? <InfoIconTooltip title={computeToolTip()}/> : <></>}
            </div>}>
            <Grid container direction={"column"}>
                {permissions.canAddNewShipmentAdj({isAdmin, ...props}) &&
                    <Grid item>
                        <a
                            style={{color: "blue"}}
                            onClick={() => props.onAddEditEntryClick(props.data, props.entryType)}
                        >+
                            Add {props.entryType.type === "carrier" ? "Carrier" : "Customer"} {props.entryType.subType === "increase" ? "Cost" : "Reduction"}</a>
                    </Grid>
                }
                <Grid item>
                    <Grid container className={"financial-adjustment_header"}>
                        <Grid item xs={6}>
                            <p>Reason / Description</p>
                        </Grid>
                        <Grid item xs={3}>
                            <p>Cost</p>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                    </Grid>
                    {props.data.length !== 0 ? props.data.map((cellData, index) => {
                            return <FinancialAdjustmentCell
                                key={cellData.id}
                                data={props.data}
                                onAddEditEntryClick={props.onAddEditEntryClick}
                                activeIndex={index}
                                entryType={props.entryType}
                            />
                        }) :
                        <p style={{fontSize: ".9rem"}}>{`No added ${props.entryType.type === "carrier" ? "Carrier" : "Customer"} ${props.entryType.subType === "increase" ? "costs" : "reductions"} at this time`}</p>
                    }

                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default FinancialTransactionCard;
