import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import LabelOrLink from "../../../../../crm/components/cardHelperComponents/LabelOrLink";
import HotNote from "../../../../shipments/DetailsPage/includes/HotNote";
import {TgfButtonGroupInput} from "../../../../../core/kendo/form/inputs/TgfButtonGroupInput";
import TgfComboBox from "../../../../../core/kendo/form/inputs/TgfComboBox";
import React, {useState} from "react";
import StringUtils from "../../../../../core/utils/StringUtils/StringUtils";
import TgfTextBox from "../../../../../core/kendo/form/inputs/TgfTextBox";
import {Label} from "@progress/kendo-react-labels";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import CrmTypedAddressBookListingComboBox from "../../../../../crm/components/CrmTypedAddressBookListingComboBox";
import AddNewAddressLabelLink from "../../../../../crm/components/cardHelperComponents/AddNewAddressLabelLink";
import ReturnToShipmentConfirmModal from "../../../../../core/kendo/dialog/prefab/ReturnToShipmentConfirmModal";

export const DestinationCard = (props) => {
    const {
        type,
        formValues,
        setFormValues,
        applyFormValue,
        fieldErrors,
        destinationLocationTypes = [],
        deliverySiteOptions = [],
        destinationAccessorialOptions = [],
        setIsDirty,
        defaultConsigneeContent,
    } = props;

    const [showReturnToShipmentConfirmModal, setShowReturnToShipmentConfirmModal] = useState(false);
    const [confirmCompany, setConfirmCompany] = useState(null);

    const filterShipperConsignee = (filter) => {
        return {
            ...filter,
            $or: [
                {companyIsDisabled: false},
                {companyIsDisabled: null}
            ],
            affiliateCompanyId: formValues.customer.companyId,
            companyIsInactive: false,
            primaryContactId: {
                $ne: null
            }
        };
    };

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value);
    };

    const handleDestinationZipChange = (e) => {
        const clone = {...formValues};
        clone.consignee = defaultConsigneeContent;
        clone.consignee.companyPostalCode = e.target.value;
        clone.destinationCountry = StringUtils.isFirstCharNumberOrLetter(e.target.value) === "NUMBER" ? "USA" : "CA";
        clone[e.target.element.name] = e.target.value;
        setFormValues({...clone});
        setIsDirty(true);
    };

    const handleConsigneeChange = async (e) => {
        const consignee = e;
        const clone = {...formValues};
        clone.consignee = consignee ? consignee : defaultConsigneeContent;
        if (consignee?.companyId) clone.consignee.selectedFromFirstPage = true;
        if (consignee?.companyPostalCode) {
            clone.destinationZipPostal = consignee.companyPostalCode;
            clone.destinationCountry = StringUtils.isFirstCharNumberOrLetter(clone.destinationZipPostal) === "NUMBER" ? "USA" : "CA";
        } else {
            clone.destinationZipPostal = null;
        }
        setFormValues({...clone});
        setIsDirty(true);
    };

    const handleDeliverySiteChange = (e) => {
        const clone = {...formValues};
        if (e.target.value?.id) {
            clone.destinationLocationType = null;
        } else {
            clone.destinationLocationType = destinationLocationTypes.find(x => x.display === "Business").id;
        }
        clone.deliverySite = e.target.value?.id;
        setFormValues({...clone});
        setIsDirty(true);
    };

    const confirmNewAddress = (company) => {
        setConfirmCompany(company);
        setShowReturnToShipmentConfirmModal(true);
    };

    const closeReturnToShipmentModal = () => {
        setShowReturnToShipmentConfirmModal(false);
    };

    return (
        <AppMuiCard title={'Destination'}>
            <Grid container spacing={2}>
                {showReturnToShipmentConfirmModal &&
                    <ReturnToShipmentConfirmModal
                        closeIcon={false}
                        confirmCompany={confirmCompany}
                        closeReturnToShipmentModal={closeReturnToShipmentModal}
                        confirmBtnText={"RETURN TO SHIPMENT"}
                    />
                }
                <Grid item xs={12} md={12} lg={4}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Label>Zip/Postal Code</Label>
                        <InfoIconTooltip
                            title={type === "customerQuote" ?
                                <>
                                    <p>Zip/Postal Codes are required at the time of quoting and cannot be changed later
                                        during the booking process.</p>
                                    <p>Correct zip/postal codes are essential for accurate rates and avoiding shipment
                                        issues.</p>
                                    <p>Canadian Postal Codes must be in XXX XXX format where it has a space in the
                                        middle and the letters MUST be capitalized. Otherwise, the shipments will fail
                                        to quote. If you selected an existing address as the shipper or consignee with
                                        the postal code in the incorrect format, you must update the involved address
                                        first, then select the address again accordingly.</p>

                                </>
                                :
                                <>
                                    <p>Zip/Postal Codes are required at the time of quoting and cannot be changed later
                                        during the booking process.</p>
                                    <p>Correct zip/postal codes are essential for accurate rates and avoiding shipment
                                        issues.</p>
                                    <p>Canadian Postal Codes must be in XXX XXX format where it has a space in the
                                        middle and the letters MUST be capitalized. Otherwise, the shipments will fail
                                        to quote.</p>
                                </>
                            }
                            maxWidth={400}
                        />
                    </div>
                    <TgfTextBox
                        id={'destinationZipPostal'}
                        name={"destinationZipPostal"}
                        value={formValues.destinationZipPostal}
                        onChange={handleDestinationZipChange}
                        maxLength={7}
                        tabIndex={3}
                    />
                    <FieldError>{fieldErrors.destinationZipPostal}</FieldError>
                </Grid>
                {type === "customerQuote" ?
                    <>
                        <Grid item className={'centerText'} xs={12} md={12} lg={1}>
                            <br />
                            <label>OR</label>
                        </Grid>
                        <Grid item className={'boldField'} xs={12} md={12} lg={7}>
                            {formValues.consignee?.companyName &&
                                <LabelOrLink className={'boldField'}
                                             labelText={"Consignee"} selected={formValues.consignee} styles={{color:'#28a745'}}
                                />
                            }
                            {formValues.customer && !formValues.consignee?.companyName &&
                                <AddNewAddressLabelLink
                                    labelText={"Consignee"}
                                    styles={{color:'#546E7A'}}
                                    selectedCustomer={formValues.customer}
                                    onSaveNewAddress={handleConsigneeChange}
                                    confirmNewAddress={confirmNewAddress}
                                />
                            }
                            {formValues.consignee?.hotNote &&
                                <HotNote company={formValues.consignee} />
                            }
                            <CrmTypedAddressBookListingComboBox
                                style={{width: '100%'}}
                                types={[CrmTypedAddressBookListingComboBox.Customer, CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                                value={formValues.consignee.companyId ? formValues.consignee : null}
                                onChange={handleConsigneeChange}
                                filterMap={filterShipperConsignee}
                                placeholder="Search..."
                                popupIcon={' '}
                                disabled={!formValues.customer}
                                additionalInputProps={{tabindex: 4}}
                            />
                        </Grid>
                    </>
                    :
                    <Grid item xs={12} md={12} lg={8}></Grid>
                }
                <Grid item xs={false} md={false} lg={3}> </Grid>
                <Grid item xs={12} md={12} lg={5}>
                    <TgfButtonGroupInput
                        label={"Location Type"}
                        id={"destinationLocationType"}
                        name={"destinationLocationType"}
                        value={formValues.destinationLocationType}
                        data={destinationLocationTypes}
                        onChange={handleChange}
                        disabled={(formValues.deliverySite)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <TgfComboBox
                        label={"Non Commercial Delivery Site"}
                        id={"deliverySite"}
                        name={"deliverySite"}
                        data={deliverySiteOptions}
                        value={formValues.deliverySite}
                        onChange={handleDeliverySiteChange}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TgfButtonGroupInput
                        label={"Accessorials"}
                        id={"destinationAccessorials"}
                        name={"destinationAccessorials"}
                        value={formValues.destinationAccessorials}
                        data={destinationAccessorialOptions}
                        isMultiSelect={true}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};