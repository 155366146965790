import {Grid} from '@material-ui/core';
import AppButton from '../../../core/components/AppButton/AppMuiButton';
import {Save, Undo} from '@material-ui/icons';
import CostSummary from './includes/CostSummary';
import AuditConfirmation from './includes/AuditConfirmation';
import CarrierAddress from './includes/CarrierAddress';
import RemitAddress from './includes/RemitAddress';
import React from 'react';
import Core from '@atomos/core';
import FactoringCompanySelector from './includes/FactoringCompanySelector';
import DoNotPayToggle from "./includes/DoNotPayToggle";
import {ShipmentTitle} from "../ShipmentTitle";

const renderAuditForm = ({values, errors, isDirty, isValid, setFieldValue, resetForm}) => {

  const shipmentHasFinalized = values.finalizingAssociateId !== null &&
    values.auditFinalizedDate !== null;

  const costSummaryProps = {
    customerCost: Core.Utils.get(values.invoice, 'customerCost', 0),
    carrierCost: Core.Utils.get(values.invoice, 'carrierCost', 0),
    adjustedCustomerCost: Core.Utils.get(values.invoice, 'adjustedCustomerCost', 0),
    adjustedCarrierCost: Core.Utils.get(values.invoice, 'adjustedCarrierCost', 0),
    totalCarrierCost: Core.Utils.get(values.invoice, 'totalCarrierCost', 0)
  };

  const carrierAddressProps = {
    name: Core.Utils.get(values.shipment, 'carrier.name', ''),
    mcNumber: Core.Utils.get(values.shipment, 'carrier.mcNumber', ''),
    address1: Core.Utils.get(values.shipment, 'carrier.address1', ''),
    address2: Core.Utils.get(values.shipment, 'carrier.address2', ''),
    city: Core.Utils.get(values.shipment, 'carrier.city', ''),
    stateProvince: Core.Utils.get(values.shipment, 'carrier.stateProvince', ''),
    postalCode: Core.Utils.get(values.shipment, 'carrier.postalCode', '')
  };

  const auditConfirmationProps = {
    invoicedAmount: values.invoicedAmount,
    invoiceAuditDate: values.invoiceAuditDate,
    invoiceDueDate: values.invoiceDueDate,
    invoicedAmountError: errors.invoicedAmount,
    invoiceAuditDateError: errors.invoiceAuditDate,
    invoiceDueDateError: errors.invoiceDueDate,
    markAsFinalized: values.markAsFinalized,
    finalizingAssociateName: Core.Utils.get(values.finalizingAssociate, 'fullName', null),
    auditFinalizedDate: values.auditFinalizedDate,
    shipmentFinalized: shipmentHasFinalized,
    values: values,
    onCopyCarrierCost: () =>
      setFieldValue('invoicedAmount', Core.Utils.get(values.invoice, 'totalCarrierCost', null)),
    onFieldChange: (key, value) =>
      setFieldValue(key, value),
    onMarkFinalized: () => {
      setFieldValue('finalizingAssociateId', values.associate.id);
      setFieldValue('auditFinalizedDate', new Date());
      setFieldValue('finalizingAssociate', values.associate);
      setFieldValue('markAsFinalized', true)
    }
  };

  const remitAddressProps = {
    remitToName: values.remitToName,
    remitToAddress1: values.remitToAddress1,
    remitToAddress2: values.remitToAddress2,
    remitToCity: values.remitToCity,
    remitToStateProvince: values.remitToStateProvince,
    remitToPostalCode: values.remitToPostalCode,
    shipmentFinalized: shipmentHasFinalized,
    values: values,
    onFieldChange: (key, value) => {
      setFieldValue(key, value);
    },
    onUseCarrierAddressClick: () => {
      setFieldValue('remitToName', Core.Utils.get(values.shipment, 'carrier.name', ''));
      setFieldValue('remitToAddress1', Core.Utils.get(values.shipment, 'carrier.address1', ''));
      setFieldValue('remitToAddress2', Core.Utils.get(values.shipment, 'carrier.address2', ''));
      setFieldValue('remitToCity', Core.Utils.get(values.shipment, 'carrier.city', ''));
      setFieldValue('remitToStateProvince', Core.Utils.get(values.shipment, 'carrier.stateProvince', ''));
      setFieldValue('remitToPostalCode', Core.Utils.get(values.shipment, 'carrier.postalCode', ''));
    },
    onSelectFactoringCompanyAddressClick: async () => {
      const companies = await values.getFactoringCompanies();
      setFieldValue('factoringCompanies', companies);
      setFieldValue('showFactoringCompanySelector', true);
    }
  };

  const factoringCompanySelectorProps = {
    isOpen: values.showFactoringCompanySelector,
    factoringCompanies: values.factoringCompanies,
    onClose: () => {
      setFieldValue('factoringCompanies', []);
      setFieldValue('showFactoringCompanySelector', false);
    },
    onChange: (factoringCompany) => {
      setFieldValue('remitToName', factoringCompany.companyName);
      setFieldValue('remitToAddress1', factoringCompany.companyAddress1);
      setFieldValue('remitToAddress2', factoringCompany.companyAddress2);
      setFieldValue('remitToCity', factoringCompany.companyCity);
      setFieldValue('remitToStateProvince', factoringCompany.companyStateProvince);
      setFieldValue('remitToPostalCode', factoringCompany.companyPostalCode);
      setFieldValue('factoringCompanies', []);
      setFieldValue('showFactoringCompanySelector', false);
    }
  };

  const doNotPayCarrierProps = {
    canEdit: true,
    doNotPayCarrierValue: values.doNotPayCarrier,
    doubleBrokeredValue: values.doubleBrokered,
    disabled: !values.isAdmin || values.shipment.hasOwnProperty("voidId"),
    onDoNotPayCarrierChange: (value) => {
      setFieldValue('doNotPayCarrier', value);
    },
    onDoubleBrokeredChange: (value) => {
      setFieldValue('doubleBrokered', value);
    }
  };

  const handleResetClick = (e) =>
    resetForm();

  const auditingButtonHandler = shipmentHasFinalized ?
    values.onRevertFinalizeAuditClick :
    values.onFinalizedAuditClick;

  const auditingButtonLabel = shipmentHasFinalized ?
    'Revert Audit' : 'Finalize Audit';

  const hasIncompleteAuditFields = values.invoicedAmount === null ||
    values.invoiceAuditDate === null ||
    values.invoiceDueDate === null;

  const auditingButtonDisabled = isDirty ||
    hasIncompleteAuditFields;

  const showDoNotPayCarrierWarning = values.doNotPayCarrier;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item>
          <ShipmentTitle bolNumber={values.bolNumber} title={`Shipments - Auditing - ${values.bolNumber ? values.bolNumber : 'New'}`}/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <AppButton
              startIcon={<Save/>}
              type={'submit'}
              disabled={!isValid || !isDirty || values.shipment.hasOwnProperty('voidId')}
            >
              Save
            </AppButton>
          </Grid>
          <Grid item>
            <AppButton
              startIcon={<Undo/>}
              color="warning"
              onClick={handleResetClick}
              disabled={!isDirty || values.shipment.hasOwnProperty('voidId')}
            >
              Reset
            </AppButton>
          </Grid>
          <Grid item>
            <AppButton
              look={'outline'}
              color={'primary'}
              disabled={auditingButtonDisabled || values.shipment.hasOwnProperty('voidId')}
              onClick={auditingButtonHandler}
            >
              {auditingButtonLabel}
            </AppButton>
          </Grid>
          <Grid item>
            {showDoNotPayCarrierWarning && <div className={'do-not-pay-carrier-warning'}>***DO NOT PAY CARRIER AT THIS TIME***</div>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={3} md={3}>
            <CostSummary
              {...costSummaryProps}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <CarrierAddress
              {...carrierAddressProps}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <DoNotPayToggle
              {...doNotPayCarrierProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={3} md={3}>
            <AuditConfirmation
              {...auditConfirmationProps}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <RemitAddress
              {...remitAddressProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <FactoringCompanySelector
        {...factoringCompanySelectorProps}
      />
    </Grid>
  );
};

export default renderAuditForm;
