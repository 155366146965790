import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography} from "@material-ui/core";
import AllTimeHighsListing from "./AllTimeHighsListing";

const AgentAllTimeHighsListing = (props) => {

    const associateGridRef = useRef();
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const [associateRelatedData, setAssociateRelatedData] = useState([]);
    const [associateGridReady, setAssociateGridReady] = useState(false);

    const loadAssociateGridData = async (odata) => {

        odata.top = 1;
        odata.skip = 0;
        odata.associateId = employeeContext.agency.id;

        const selectedResultType = props.resultType.value.toLowerCase();
        odata[selectedResultType] = 1;

        const data = await window.shell.gateway.calculateAssociateAllTimeHighs(odata);
        setAssociateRelatedData(data.data);
        setAssociateGridReady(true);

        return data;
    };

    useEffect(() => {
        if (associateGridReady)
            associateGridRef?.current?.refresh();
    }, [props.resultType]);

    return (
        <Box className={'all-time-highs-listing'}>
            <Typography variant={"h5"}>Agent</Typography>
            <AllTimeHighsListing
                ref={associateGridRef}
                pageable={{
                    pageSize: 1,
                    buttonCount: 1,
                    pageSizes: [],
                }}
                onLoadDataPage={loadAssociateGridData}
                orderBy={['fullName asc']}
                sort={[{field: "fullName", dir: "asc"}]}
                relatedData={associateRelatedData} />
        </Box>)
};

export default AgentAllTimeHighsListing;