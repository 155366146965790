import React, {useEffect, useState} from 'react';
import {
    AccountBalance,
    Assessment,
    DateRange,
    Home,
    Pageview,
    ContactPhone,
    Receipt,
    Timeline,
    PersonAddDisabled, AttachMoney
} from '@material-ui/icons';

import ComponentBuilder from '../../core/ComponentBuilder';
import AppSideBar from '../../core/components/AppSideBar';
import useIsAgencyOps from "../../hubs/personaV2/selectors/useIsAgencyOps";

const ReportUrl = '/reports';

const topReportNavItem = { title: 'Reports', icon: Home, key: 'reports', href: `${ReportUrl}` };

const BaseLinks = [
  { title: 'Cancelled/Voided', icon: DateRange, key: 'void-shipment-report', href: `${ReportUrl}/cancelled-voided-report` },
  { title: 'Credit App on File', icon: PersonAddDisabled, key: 'missing-credit-app', href: `${ReportUrl}/missing-credit-app` },
  { title: 'Customer Report', icon: Assessment, key: 'customer-report', href: `${ReportUrl}/customer-report` },
  { title: 'Invoice Aging', icon: Receipt, key: 'invoice-aging', href: `${ReportUrl}/invoice-aging` },
  { title: 'Invoice Aging Summary', icon: Receipt, key: 'invoice-aging-summary', href: `${ReportUrl}/invoice-aging-summary` },
  { title: 'Lane Look Up/History', icon: Timeline, key: 'lane-lookup', href: `${ReportUrl}/lane-lookup` },
  { title: 'Last Shipment Report', icon: DateRange, key: 'last-shipment-report', href: `${ReportUrl}/last-shipment-report?filter=0-30` },
  { title: 'Missing Primary Contacts', icon: ContactPhone, key: 'missing-primary-contacts', href: `${ReportUrl}/missing-primary-contacts` },
  { title: 'Search Loads', icon: Pageview, key: 'search-loads', href: `${ReportUrl}/search-loads` }
];

const Wrapper = (props) => {
  const {
    canViewMarginContribution,
    isNotAgencyOps,
    showLockedShipmentsReport
  } = props;

  const [links, setLinks] = useState([]);

  const computeAdditionalNavLinks = () => {
    let allLinks = [...props.links];

    if (isNotAgencyOps) {
      allLinks.push(
          { title: 'Agent Rewards Programs Report', icon: AttachMoney, key: 'agent-rewards-programs-report', href: '/reports/agent-rewards-programs-report'},
      );
    }

    if (isNotAgencyOps) {
      allLinks.push(
          { title: 'All-Time Highs Report', icon: DateRange, key: 'all-time-highs-report', href: '/reports/all-time-highs-report'},
      );
    }

    if (isNotAgencyOps) {
      allLinks.push(
          { title: 'Commission Center', icon: AccountBalance, key: 'commission-center', href: `${ReportUrl}/commission-center` }
      );
    }

    if(showLockedShipmentsReport) {
      allLinks.push(
          {title: 'Locked Shipments Report', icon: DateRange, key: 'locked-shipments-report', href: `${ReportUrl}/locked-shipments-report`}
      );
    }

    if (isNotAgencyOps) {
        allLinks.push(
            {title: 'Shipment History Report', icon: DateRange, key: 'shipment-history-report', href: `${ReportUrl}/shipment-history-report`}
        );
    }

    allLinks.push(
        {title: 'Carrier Contact Report', icon: DateRange, key: 'carrier-contact-report', href: `${ReportUrl}/carrier-contact-report`}
    );

    if(canViewMarginContribution) {
      allLinks.push(
          { title: 'Margin Contribution', icon: DateRange, key: 'margin-contribution', href: `${ReportUrl}/margin-contribution` }
      );
    }

    if (isNotAgencyOps) {
      allLinks.push(
          { title: 'Monthly GM Report', icon: DateRange, key: 'monthly-gross-margin', href: `${ReportUrl}/monthly-gross-margin` },
      );
    }

    allLinks.sort((a,b) => a.title.localeCompare(b.title));
    allLinks.unshift(topReportNavItem);
    setLinks(allLinks)
  }

  useEffect(() => {
    if (props.links.length > 0) {
      computeAdditionalNavLinks();
    }
  }, [props.links])

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppSideBar menuList={links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
  );
};

export default ComponentBuilder
  .wrap(Wrapper)
  .stateToProps((state, ownProps) => {
    return {
      links: BaseLinks,
      canViewMarginContribution: state.persona.associate.canViewMarginContribution,
      isNotAgencyOps: !useIsAgencyOps(),
      showLockedShipmentsReport: state?.utility?.featureFlags?.arReleaseHidden === false
    };
  })
  .build();
