import React from 'react';
import AppMuiCard from "../../../core/components/cards/AppCard";
import AppText from "../../../core/components/text/AppText";
import TotalMarginPace from "./TotalMarginPace";

const TotalMarginPaceTable = (props) => {

    const {date, grossMarginData} = props;

    return grossMarginData?.total ? (
    <AppMuiCard>
      <h1>
        <AppText
          value={" Total Margin Pace: "}
          style={
            grossMarginData.total.margin < 0
              ? { color: "red" }
              : { color: "blue" }
          }
        ></AppText>
        <TotalMarginPace date={date} grossMarginData={grossMarginData} />
      </h1>
    </AppMuiCard>
    ) : (
        <React.Fragment></React.Fragment>
    );
};

export default TotalMarginPaceTable;
