import React, {useEffect, useState} from "react";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";

const HomeMonthAllTimeHigh = () => {

    const isAdmin = useIsAdministrator();
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const [highs, setHighs] = useState(null);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
    }, [highs]);

    const loadData = async () => {
        const searchOptions = {
            top: 1,
            skip: 0,
            monthly: 1,
            excludeData: 1
        };

        if (isAdmin) {
            const results = await window.shell.gateway.calculateAllTimeHighs(searchOptions);
            setHighs(results.items[0]);
        }
        else {
            searchOptions['associateId'] = employeeContext.agency.id;
            const results = await window.shell.gateway.calculateAssociateAllTimeHighs(searchOptions);
            setHighs(results.items[0]);
        }
    };

    return (highs ? <>
        <div>Monthly All-Time High</div>
        <div><AppCurrencyText value={highs?.totalMarginHigh ?? 0} /></div>
    </> : <></>);
}

export default HomeMonthAllTimeHigh;