import React from "react";
import InfoModal from "../InfoModal";

const PrepayCustomerModal = (props) => {

    const { closePrepayModal, closeIcon } = props;

    const prePayModalDescription = (
        <div style={{ textAlign: 'center' }} >
            <span><p><b>IMPORTANT</b></p></span>
            <span><p><b>The customer you are wanting to use on this shipment is a prepay customer. If you have not done so already,
                  please work with the Credit department as needed to make sure the proper payment arrangements have been made.</b></p></span>
        </div>
    )

    return (
        <InfoModal
            title={`Prepay Customer Alert`}
            description={prePayModalDescription}
            onClose={closePrepayModal}
            closeIcon={closeIcon}
            width={800}
        />
    );
};

export default PrepayCustomerModal;
