import TotalMarginPace from "../../../../crm/components/tables/TotalMarginPace";

const HomeMonthMarginPace = (props) => {

    const startDate = new Date();
    startDate.setDate(1);

    const endDate = new Date();
    endDate.setMonth(startDate.getMonth() + 1);
    endDate.setDate(1);

    const { grossMarginData } = props;

    return <>
        <div>Current Month Pace</div>
        <div>
            {grossMarginData ? <TotalMarginPace date={startDate} grossMarginData={grossMarginData} /> : <></>}
        </div>
    </>;
}

export default HomeMonthMarginPace;