import {Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {warningIcon} from "../../../../core/media/icons/exportCustomIcons";
import React from "react";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const AdjustedCostSection = (props) => {

    const { invoice } = props;

    return (
        <Grid container spacing={2} alignItems={"center"} style={{padding: 10}}>
            <Grid item>
                <p>Carrier Audit Cost <InfoIconTooltip title={
                    <>
                        <p>The Carrier Audit Cost is specifically the amount we should owe the carrier.</p>
                        <p>It is dependent upon the Orig. Carrier Cost and how (if any) Added/Deducted Carrier Costs are added.</p>
                        <p>This will be the amount that is displayed on generated rate confirmations.</p>
                    </>
                }/></p>
            </Grid>
            <Grid item>
                <div style={{position: "relative"}}>
                    <NumericTextBox
                        id={"totalCarrierCost"}
                        name={"totalCarrierCost"}
                        value={invoice.totalCarrierCost || 0}
                        format={"c2"}
                        spinners={false}
                        disabled={true}
                        style={{color: "#00152d", fontWeight: "500"}}
                    />
                    {!invoice.totalCarrierCost &&
                        <img style={{height: 19, width: 19, position: "absolute", top: 5, left: 135}} src={warningIcon} alt={"Total carrier cost is $0"}/>
                    }
                </div>
            </Grid>
            <Grid item>
                <p>Adj Carrier Cost <InfoIconTooltip title={
                    <>
                        <p>This is essentially the total overall cost of the shipment which is used (with the Adj. Customer Cost) to determine the gross margin.</p>
                        <p>It includes the Orig. Carrier Cost as well as any and all Added/Deducted Carrier Costs.</p>
                    </>
                }/></p>
            </Grid>
            <Grid item>
                <div style={{position: "relative"}}>
                    <NumericTextBox
                        id={"postedAmountUsd"}
                        name={"postedAmountUsd"}
                        value={invoice.adjustedCarrierCost || 0}
                        format={"c2"}
                        spinners={false}
                        disabled={true}
                        style={{color: "#00152d", fontWeight: "500"}}
                    />
                </div>
            </Grid>
            <Grid item>
                <p>Adj. Customer Cost <InfoIconTooltip title={
                    <>
                        <p>This is the total overall customer cost of the shipment which is used (with the Adj. Carrier Cost) to determine the gross margin.</p>
                        <p>It includes the Orig. Customer Cost as well as any and all Added/Deducted Customer Costs.</p>
                    </>
                }/></p>
            </Grid>
            <Grid item>
                <div style={{position: "relative"}}>
                    <NumericTextBox
                        id={"postedAmountUsd"}
                        name={"postedAmountUsd"}
                        value={invoice.adjustedCustomerCost || 0}
                        format={"c2"}
                        spinners={false}
                        disabled={true}
                        style={{color: "#00152d", fontWeight: "500"}}
                    />
                    {!invoice.adjustedCustomerCost &&
                        <img style={{height: 19, width: 19, position: "absolute", top: 5, left: 135}} src={warningIcon} alt={"Adjusted customer cost is $0"}/>
                    }
                </div>
            </Grid>
        </Grid>
    )
}

export default AdjustedCostSection;
