import React, {useState} from "react";
import {Box, Grid} from "@material-ui/core";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import AppDropdown from "../../../core/components/dropdowns/AppDropdown";
import AdminAllTimeHighsListing from "./includes/AdminAllTimeHighsListing";
import AgentAllTimeHighsListing from "./includes/AgentAllTimeHighsListing";

import './AllTimeHighsReport.scss';
import {Info, InfoOutlined} from "@material-ui/icons";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const AllTimeHighsReport = () => {

    const isAdmin = useIsAdministrator();
    usePageTitle('All Time Highs Report');

    const resultTypes = [
        { id: 1, value: "Monthly" },
        { id: 2, value: "Yearly" }
    ];
    const [resultType, setResultType] = useState(resultTypes[0]);

    const handleResultTypeChange = (e, resultType) => {
        setResultType(resultType);
    };

    return (
        <FullWidthLayout SideNav={ReportNav} title={'All-Time Highs Report'}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                    <Box className={"info-icon-message"}>
                        <InfoOutlined  />
                        <span>This report is only available for agents and the Tallgrass Freight back office. Agency assistants cannot see this report. Hover over the data cells for tooltips on the involved month and/or year.</span>
                    </Box>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item xs={12} sm={2}>
                    <AppDropdown
                        label="Result Type"
                        disableClearable
                        options={resultTypes}
                        value={resultType}
                        onChange={handleResultTypeChange}
                        field={"value"}
                        fieldIndex={"id"} />
                </Grid>
            </Grid>
            {isAdmin && <AdminAllTimeHighsListing  resultType={resultType} />}
            {!isAdmin && <AgentAllTimeHighsListing resultType={resultType} />}
        </FullWidthLayout>);
};
export default AllTimeHighsReport
