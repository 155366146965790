import React from "react";
import {Grid} from "@material-ui/core";
import InfoModal from "../InfoModal";
import LabelOrLink from "../../../../crm/components/cardHelperComponents/LabelOrLink";

const ReturnToShipmentConfirmModal = (props) => {

    const { closeReturnToShipmentModal, closeIcon, confirmCompany, confirmBtnText } = props;

    const confirmationMessage = (
        <div style={{ textAlign: 'center' }}>
            <Grid item xs={12} >
                <span><p><b>The Shipper/Consignee was saved, affiliated to the involved customer.
                    It will be entered as the selected address for the involved field upon clicking the Return to Shipment button below.</b></p></span>
            </Grid>
            <Grid item style={{marginTop: '1.5em', marginBottom: '1.5%em', fontSize: 16}}>
                <LabelOrLink
                    labelText={confirmCompany.companyName} selected={confirmCompany} styles={{color:'#0000FF'}}
                />
            </Grid>
        </div>
    )

    return (
        <InfoModal
            title={`Shipper/Consignee Saved`}
            description={confirmationMessage}
            onClose={closeReturnToShipmentModal}
            closeIcon={closeIcon}
            confirmBtnText={confirmBtnText}
            width={800}
        />
    );
};

export default ReturnToShipmentConfirmModal;
