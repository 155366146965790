import React from "react";
import PropTypes from 'prop-types';
import {TgfTooltip} from "../../tooltips/TgfTooltip/TgfTooltip";
import {WarningText} from "../../text/WarningText/WarningText";

/**
 * A warning alert component that displays a message in red with a tooltip on hover
 */
export const WarningAlert = ({message, tooltipContent, tooltipMaxWidth = 300}) => {

    return (
        <TgfTooltip tooltipContent={tooltipContent} tooltipMaxWidth={tooltipMaxWidth}>
            <WarningText>
                {message}
            </WarningText>
        </TgfTooltip>
    )
}

WarningAlert.propTypes = {
    /** The main text message to display in red */
    message: PropTypes.string.isRequired,

    /** Content to show in the tooltip when hovering. Will be wrapped in a div with
     * the class 'tgf-kendo-grid-tooltip' and displayed in bold */
    tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

