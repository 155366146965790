import React, {useState} from 'react';
import {Delete} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import AppMuiCard from '../../../core/components/cards/AppCard/AppMuiCard';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';
import CrmTypedAddressBookListingComboBox from "../../../crm/components/CrmTypedAddressBookListingComboBox";
import SecureAction from "../permissions/SecureAction";
import SecureField from "../permissions/SecureField";
import LabelOrLink from "../../../crm/components/cardHelperComponents/LabelOrLink";
import HotNote from "../DetailsPage/includes/HotNote";
import AddNewAddressLabelLink from "../../../crm/components/cardHelperComponents/AddNewAddressLabelLink";
import ReturnToShipmentConfirmModal from "../../../core/kendo/dialog/prefab/ReturnToShipmentConfirmModal";

const MultipleStopCard = (props) => {

    const {
        customerId,
        errors,
        isMultipleStop,
        stopTypes,
        stop,
        title,
        onLocationChanged,
        onStopTypeChanged,
        onFieldChange,
        onClearCard,
        shipment,
        currentCustomer
     } = props;

    const [showReturnToShipmentConfirmModal, setShowReturnToShipmentConfirmModal] = useState(false);
    const [confirmCompany, setConfirmCompany] = useState(null);

    const filterCompany = (filter) => {
        return {
            ...filter,
            $or: [
                {companyIsDisabled: false},
                {companyIsDisabled: null}
            ],
            affiliateCompanyId: customerId,
            companyIsInactive: false
        };
    };

    const handleLocationChange = (value) => {
        onLocationChanged(value);
    };

    const handleStopTypeChange = (e, value) => {
        onStopTypeChanged(value);
    };

    const handleChange = (key) => (e) => {
        onFieldChange(key, e.target.value);
    };

    const handleDateFieldChange = (key) => (date) => {
        const arrivalDate = date && date.isValid() ?
            date.toDate() : null;
        onFieldChange(key, arrivalDate);
    };

    const handleClearCard = () => {
        onClearCard();
    };

    const confirmNewAddress = (company) => {
        setConfirmCompany(company);
        setShowReturnToShipmentConfirmModal(true);
    };

    const closeReturnToShipmentModal = () => {
        setShowReturnToShipmentConfirmModal(false);
    };

    const clearButton = (
        <SecureAction
            pageName={'multipleStops'}
            actionName={'clearStop'}
            as={AppIconButton}
            Icon={Delete}
            tooltip={'Clear the stop information.'}
            color={'danger'}
            onClick={handleClearCard}
            // Need to talk mike about when the red trash can should show up.
            style={{padding: 2}}
            disabled={shipment.hasOwnProperty("voidId")}
        />
    );

    const defaultCompany = {
        address1: null,
        address2: null,
        affiliateId: shipment?.companyId,
        businessPhone: null,
        categoryTypeId: 2,
        city: null,
        name: null,
        customerName: shipment?.companyName,
        faxPhone: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        stateProvince: null,
        postalCode: null,
    };

    return (
        <AppMuiCard title={title} action={clearButton}>
            <Grid container spacing={2}>
                {showReturnToShipmentConfirmModal &&
                    <ReturnToShipmentConfirmModal
                        closeIcon={false}
                        confirmCompany={confirmCompany}
                        closeReturnToShipmentModal={closeReturnToShipmentModal}
                        confirmBtnText={"RETURN TO SHIPMENT"}
                    />
                }
                <Grid item xs={12} md={4}>
                    {!isMultipleStop &&
                        <LabelOrLink labelText={"Location"} selected={stop.selectedCompany} styles={{color:'#546E7A'}} />
                    }
                    {isMultipleStop && stop.selectedCompany &&
                        <LabelOrLink labelText={"Location"} selected={stop.selectedCompany} styles={{color:'#000000'}} />
                    }
                    {isMultipleStop && !stop.selectedCompany && currentCustomer &&
                        <AddNewAddressLabelLink
                            labelText={"Location"}
                            styles={{color:'#000000'}}
                            selectedCustomer={currentCustomer}
                            onSaveNewAddress={handleLocationChange}
                            confirmNewAddress={confirmNewAddress}
                        />
                    }
                    {stop.selectedCompany?.hotNote &&
                        <HotNote company={stop.selectedCompany} />
                    }
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'company'}
                        as={CrmTypedAddressBookListingComboBox}
                        style={{width: '100%'}}
                        types={[CrmTypedAddressBookListingComboBox.Customer, CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                        value={stop.selectedCompany || null}
                        onChange={handleLocationChange}
                        placeholder="Search..."
                        filterMap={filterCompany}
                        popupIcon={' '}
                        disabled={!isMultipleStop}
                    />
                    <AppValidationFailure
                        message={errors.selectedCompany}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'stopType'}
                        as={AppDropdown}
                        field={'name'}
                        fieldIndex={'id'}
                        label={'Stop Type'}
                        options={stopTypes}
                        value={stop.selectedStopType}
                        onChange={handleStopTypeChange}
                        disabled={!isMultipleStop}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <label>Arrival Date</label>
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'arrivalDate'}
                        as={AppDatePicker}
                        value={stop.arrivalDate}
                        onChange={handleDateFieldChange('arrivalDate')}
                        disabled={!isMultipleStop}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'arrivalTime'}
                        as={AppInput}
                        label={'Arrival Time'}
                        value={stop.arrivalTime || ''}
                        inputProps={{
                            maxLength: 20
                        }}
                        onChange={handleChange('arrivalTime')}
                        disabled={!isMultipleStop}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'reference'}
                        as={AppInput}
                        label={'Arrival Reference'}
                        value={stop.reference || ''}
                        inputProps={{
                            maxLength: 30
                        }}
                        onChange={handleChange('reference')}
                        disabled={!isMultipleStop}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecureField
                        pageName={'multipleStops'}
                        fieldName={'note'}
                        as={AppInput}
                        rows={4}
                        multiline={true}
                        label={'Notes'}
                        inputProps={{
                            maxLength: 400
                        }}
                        value={stop.note || ''}
                        onChange={handleChange('note')}
                        disabled={!isMultipleStop}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default MultipleStopCard;
