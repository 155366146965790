import React, {useState, useEffect} from "react";
import {Grid} from "@material-ui/core";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import {useSelector} from "react-redux";
import TgfDataGrid from "../../../core/kendo/dataGrid/TgfDataGrid";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import {Link} from "react-router-dom";
import "./VoidShipmentReport.scss";
import {ShipmentNumberCell} from "../../open-load-board/includes/listingCells/multiUseListingCells";
import TgfMonthPicker from "../../../core/kendo/inputs/TgfMonthPicker/TgfMonthPicker";
import DateUtils from "../../../core/utils/dateUtils/dateUtils";
import {Checkbox} from "@progress/kendo-react-inputs";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const VoidShipmentReport = () => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    usePageTitle("TGF: Cancelled/Voided");

    const cellLink = (path, title) => {
        const rowLink = <td><Link className={"tgf-grid-link-cell"} to={path}
                                  target={openLinksInNewTab ? "_blank" : ""}>{title}</Link></td>;
        return rowLink;
    }

    const GridColumns = [
        {
            field: 'bolNumber',
            title: 'Ship #',
            width: 60,
            type: 'custom',
            cell: ShipmentNumberCell
        },
        {
            field: 'shipmentRep',
            title: 'Rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.shipmentRep} agentId={props.dataItem.shipmentRepId}/>
                </span>
              </td>,
            width: 40,
        },
        {
            field: 'pickupDate',
            title: 'Pickup Date',
            type: 'date'
        },
        {
            field: 'customerName',
            title: 'Customer Name',
            type: 'custom',
            cell: (props) => cellLink(`/address-book/${props.dataItem.customerId}/details`, props.dataItem.customerName),
            width: 250,
        },
        {
            field: 'adjustedCustomerCost',
            title: 'Adjusted Cust. Cost',
            type: 'currency'
        },
        {
            field: 'carrierName',
            title: 'Carrier',
            type: 'custom',
            cell: (props) => cellLink(`/carrier/${props.dataItem.carrierMcNumber}/details`, props.dataItem.carrierName),
            width: 250,
        },
        {
            field: 'adjustedCarrierCost',
            title: 'Carrier Cost',
            type: 'currency'
        },
        {
            field: 'lockedDate',
            title: 'Locked Date',
            type: 'date'
        },
        {
            field: 'isAuditFinalized',
            title: 'Audit Finalized',
            type: 'custom',
            cell: (props) => <td>{props.dataItem.isAuditFinalized === 1 ? 'Yes' : 'No'}</td>,
            width: 60,

        },
        {
            field: 'previousStatus',
            title: 'Previous Shipment Status',
            type: 'text'
        },
        {
            field: 'voidReason',
            title: 'Cancelled/Voided Reason',
            type: 'custom',
            cell: (props) => <td><span>{props.dataItem.voidReason ?? ' '}</span></td>,
            width: 300,
        },
        {
            field: 'voidingRep',
            title: 'Voided By',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.voidingRep} agentId={props.dataItem.voidingRepId}/>
                </span>
              </td>,
            width: 60
        },
        {
            field: 'voidDateTime',
            title: 'Cancelled/Voided Date',
            type: 'dateFromUTCDateTime',
            width: 110
        },
    ];

    const ref = React.useRef();

    const globalState = useSelector(state => state);
    const associate = globalState.persona.associate;
    const associateRole = associate.roles[0].name;

    const [filterAssociate, setFilterAssociate] = useState(null);
    const [startOfMonth, setStartOfMonth] = useState(DateUtils.getFirstDayOfCurrentMonth());
    const [viewByPickupDate, setViewByPickupDate] = useState(true);
    const [viewByVoidDate, setViewByVoidDate] = useState(false);
    const [sort, setSort] = useState([{field: "pickupDate", dir: "desc"}]);
    const [pageReady, setPageReady] = useState(false);

    useEffect(() => {
        if (pageReady) {
            ref?.current?.refresh();
        }
    }, [startOfMonth, viewByPickupDate, viewByPickupDate, sort]);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};

        if (filterAssociate) {
            filter.shipmentRepId = filterAssociate.id;

        }

        if (startOfMonth) {
            // get end of selected month based on the selected start of month.
            const endOfSelectedMonth = new Date(startOfMonth.getUTCFullYear(), startOfMonth.getUTCMonth() + 1, 0);
            endOfSelectedMonth.setUTCHours(23, 59, 59, 999);
            if(viewByPickupDate) filter.pickupDate = {ge: startOfMonth.toISOString(), le: endOfSelectedMonth.toISOString()}
            if(viewByVoidDate) filter.voidDateTime = {ge: startOfMonth.toISOString(), le: endOfSelectedMonth.toISOString()}
        }

        odata.filter = filter;

        return odata;
    }

    const loadGridData = async (odata) => {
        const result = await window.shell.gateway.getVoidShipmentReport(odata);
        setPageReady(true);
        return result;
    };

    const handleFilterAssociate = async (e) => {
        const value = e.target.value ?? "";
        await setFilterAssociate(value);
        await ref.current.refresh();
    }

    const handleMonthChange = async (e) => {
        if (!e.target?.value) return setStartOfMonth(null);
        setStartOfMonth(e.target.value);
    }

    const handleViewByPickupDateChange = async () => {
        setSort([{field: "pickupDate", dir: "desc"}])
        setViewByVoidDate(false);
        setViewByPickupDate(true);
    }

    const handleViewByVoidDateChange = () => {
        setSort([{field: "voidDateTime", dir: "desc"}])
        setViewByPickupDate(false);
        setViewByVoidDate(true);
    }

    return (
      <FullWidthLayout SideNav={ReportNav} title="Cancelled/Voided Report">
          <Grid container spacing={2} direction="column">
              <Grid container spacing={2} alignItems={"flex-end"} style={{marginBottom: 20}}>

                  <Grid item xs={2}>
                      <TgfMonthPicker
                        id={"startOfMonth"}
                        name={"startOfMonth"}
                        label={"Month/Year"}
                        value={startOfMonth || null}
                        onChange={handleMonthChange}
                      />
                  </Grid>
                  <Grid item>
                      <Checkbox
                        value={viewByPickupDate}
                        id={"viewByPickupDate"}
                        name={"viewByPickupDate"}
                        label={"View By Pickup Date"}
                        onChange={handleViewByPickupDateChange}
                      />
                  </Grid>
                  <Grid item>
                      <Checkbox
                        value={viewByVoidDate}
                        id={"viewByVoidDate"}
                        name={"viewByVoidDate"}
                        label={"View By Cancelled/Voided Date"}
                        onChange={handleViewByVoidDateChange}
                      />
                  </Grid>
                  {associateRole === "Ace" &&
                    <Grid item>
                        <TgfAssociateAutoComplete
                          id={"filterAssociate"}
                          name={"filterAssociate"}
                          onChange={handleFilterAssociate}
                        />
                    </Grid>
                  }
                  <Grid item style={{paddingBottom: 15}}>
                      <span style={{fontWeight: "bold", color: "black"}}>
                          ***IMPORTANT – Details for shipments displayed on this report resemble the corresponding
                          information at the time the shipment was cancelled/voided.***
                      </span>
                  </Grid>
              </Grid>

              <Grid item>
                  <TgfDataGrid
                    className={"void-shipment-report-grid"}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500],
                    }}
                    ref={ref}
                    onLoadDataPage={loadGridData}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    gridColumns={GridColumns}
                    sort={sort}
                  />
              </Grid>
          </Grid>
      </FullWidthLayout>
    )
}
export default VoidShipmentReport
