import React, {useEffect, useState} from "react";
import {WarningAlert} from "../WarningAlert/WarningAlert";
import {useTgfRequest} from "../../../hooks/useTgfRequest";
import {TgfSimpleTable} from "../../table/TgfSimpleTable/TgfSimpleTable";

export const CompanyInvoicePastDueAlert = ({companyId}) => {
    const [summary, setSummary] = useState(null);
    const [getCompanyInvoiceAgingSummary] = useTgfRequest({
        onRequest: async () => {
            setSummary(await window.shell.gateway.getInvoiceAgingSummerByCompanyId(companyId))
        }
    });

    useEffect(() => {
        companyId ? getCompanyInvoiceAgingSummary(companyId) : setSummary(null);
    }, [companyId]);

    const is16DaysOrMorePastDue = (
        summary?.range16To30Count
        || summary?.range31To45Count
        || summary?.range46To60Count
        || summary?.range61To90Count
        || summary?.range91PlusCount
    );
    const columns = [
        {
            title: "1-15 Days",
            field: "range1To15Count"
        },
        {
            title: "16-30 Days",
            field: "range16To30Count"
        },
        {
            title: "31-45 Days",
            field: "range31To45Count"
        },
        {
            title: "46-60 Days",
            field: "range46To60Count"
        },
        {
            title: "61-90 Days",
            field: "range61To90Count"
        },
        {
            title: "91 Plus Days",
            field: "range91PlusCount"
        },
    ];

    const tooltipContent = (
        <>
            <p>
                This customer currently has invoices that are over 15 days past due. See below for additional information and be sure to review their profile’s Aging Shipments page.
            </p>
            <TgfSimpleTable columns={columns} data={[summary]} fontColor={"#dc3545"}/>
        </>
    )

    return ( (summary && is16DaysOrMorePastDue) &&
        <WarningAlert
            tooltipContent={tooltipContent}
            message={"***PAST DUE INVOICES ALERT***"}
            tooltipMaxWidth={500}
        />
    )
}